import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';

import {
    PushNotifications,
    PushNotification,
    PushNotificationToken,
    PushNotificationActionPerformed
} from "@capacitor/push-notifications"


import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { BusEta } from '../app';


@Injectable({
    providedIn: 'root'
})
export class PublicFirebaseService {

    private _localId = 10000;

    private _subject = new Subject<Object>();

    public userId: string;

    constructor(
        private _platform: Platform,
        private _httpService: HttpService
    ) { }

    public async init(userId: string) {

      this.userId = userId;

      if (this._platform.is("desktop") || this._platform.is("mobileweb")) {
          console.log(`[Firebase] not supported on the web platform`);
          return;
      }

      console.log(`[Firebase] public initialization`);

      this.permissions();

      this.registration();

      this.notifications();

    }

    private notifications() {
        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotification) => {
                console.log('[Firebase] Push received: ' + JSON.stringify(notification));
                this.onNotificationReceived(notification);
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            async (data: PushNotificationActionPerformed) => {
                console.log('[Firebase] Push action performed: ' + JSON.stringify(data));

                if (data.notification && data.notification.data) {
                    // trigger local notification
                    // title and text available on the data payload for remote notification
                    this._subject.next({
                        action: "clicked",
                        title: data.notification.data.title,
                        text: data.notification.data.text,
                        data: data.notification.data
                    } as BusEta.FirebaseEvent);
                }
            }
        );
    }

    private onNotificationReceived(notification: PushNotification) {
        try {
            console.log('[Firebase] Push received: ' + JSON.stringify(notification));

            // trigger local notification
            this._subject.next({
                action: "received",
                title: notification.title,
                text: notification.body,
                data: notification.data,
            } as BusEta.FirebaseEvent)
        }
        catch (err) {
            console.error(err);
        }
    }

    private permissions() {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then(result => {
            console.log('[Firebase] Permission requested, granted: ' + result.receive);

            if (result.receive == "granted") {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
            } else {
                // Show some error
            }
        });
    }

    private registration() {

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: PushNotificationToken) => {
                console.log('[Firebase] Push registration success, token: ' + token.value);
                this.saveToken(token.value);
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                console.error('[Firebase] Error on registration: ' + JSON.stringify(error));
            }
        );
    }

    public getEvents() {
        return this._subject.asObservable();
    }

    private async saveToken(token: string) {
        try {
          // save token on the backend
          await this._httpService.post("/public/firebase/token/update", {
            userId: this.userId,
            token: token
          });
          console.log(`[Firebase] Public Token updated`);
        }
        catch (err) {
            console.error(`[Firebase] Failed update token`, err);
        }
    }
}
