<ion-toolbar class="routes-list-toolbar">
    <div class="route-search">
        <ion-searchbar color="none"
                       id="search"
                       (ionChange)="search($event)"
                       placeholder="Search Route">
        </ion-searchbar>
    </div>
</ion-toolbar>
<ion-content>
    <ion-list>
        <div class="lbl">
            Routes
        </div>

        <ion-item *ngFor="let route of routes;"
                  class="route-item"
                  (click)="selectRoute(route)">
            <ion-icon src="assets/svg/route.svg"></ion-icon>
            <ion-label>{{ route.name | titlecase }}</ion-label>
        </ion-item>
    </ion-list>
    <ion-list>
        <ion-item lines="none">
            <ion-label>
                <div *ngIf="isLoading"
                     class="loading-wrapper">
                    <div class="text">
                        Loading...
                    </div>
                    <ion-progress-bar type="indeterminate"></ion-progress-bar>
                </div>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>
