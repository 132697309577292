import { Injectable } from '@angular/core';
import { BusEta } from '../app';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private _http: HttpService
  ) { }

  public async get(): Promise<BusEta.UserSettings> {
    return await this._http.post("/settings/find");
  }

  public async save(settings: BusEta.UserSettings) {
    await this._http.post("/settings/update", settings);
  }
}
