<div *ngIf="route"
     class="component">

    <div class="header">

        <div class="top-row">
            <ion-button fill="clear"
                        color="medium"
                        class="back-btn"
                        (click)="onBack()">
                <ion-icon name="chevron-back-outline"></ion-icon>
                <span class="text">Back</span>
            </ion-button>

            <div class="route-name">{{route.name}}</div>
        </div>

        <div class="bottom-row">
            <div class="stops">
                {{route.stops?.length || 0}} Stops
            </div>
        </div>
    </div>


    <div *ngIf="state">
        <div class="busses">
            <div class="lbl">
                Buses on route:
            </div>
            <div class="names">
                <div class="name"
                     *ngIf="route.devices.length > 1"
                     [ngClass]="{'active': !deviceId}"
                     (click)="selectDevice(null)">
                    All Buses</div>
                <div class="name"
                     [ngClass]="{'active': deviceId == device.id || route.devices.length == 1}"
                     (click)="selectDevice(device.id)"
                     *ngFor="let device of route.devices">
                    {{device.name}}
                </div>
            </div>
        </div>

        <div class="list">
            <div *ngFor="let stop of route.stops; let idx = index; let isLast = last;"
                 class="item">
                <div class="circle">
                    {{idx + 1}}
                </div>
                <div *ngIf="stopDeviceVisible(stop.id)"
                     class="bus-icon">
                    <img *ngIf="!stopDeviceMoving(stop.id)" src="assets/pin.svg">
                    <img *ngIf="!stopDeviceMoving(stop.id)" src="assets/bus.svg">
                    <img *ngIf="stopDeviceMoving(stop.id)" src="assets/moving.svg">
                </div>
                <div class="stop-name">
                    {{stop.name}}
                </div>
                <div class="eta">
                    <span *ngIf="getStopTime(stop.id)">
                        {{getStopTime(stop.id) |  date:'mediumTime'}}
                    </span>
                    <span *ngIf="!getStopTime(stop.id)">
                        ...
                    </span>
                </div>
                <div class="line"
                     *ngIf="!isLast"></div>
            </div>
        </div>
    </div>

</div>
