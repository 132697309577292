import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { Platform } from "@ionic/angular";
import { NotifierService } from "../../../services/notify.service";

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss'],
})
export class LoginHeaderComponent implements OnChanges {

  @Input() title: string;

  @Input() customerId: string;

  public isInitalized = false;

  public logo: string = 'assets/logo.png';

  public background: string;

  constructor(
    public platform: Platform,
    private _notify: NotifierService,
    private _customerService: CustomerService
  ) { }

  ngOnChanges() {

    if (this.customerId) {
      this.loadCustomization();
    }
    else {
      this.isInitalized = true;
    }
  }

  private async loadCustomization() {
    try {
      // load theme
      const theme = await this._customerService.getTheme(this.customerId);
      if (theme) {
        this.logo = theme.headerLogo;
        this.background = theme.portalHeaderColor;
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isInitalized = true;
    }
  }

}
