import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { BusEta } from 'src/app/app';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MapService } from 'src/app/services/map.service';
import { TravellerService } from 'src/app/services/traveller.service';
import { SettingsService } from "../../../services/settings.service";
import {AlertController, Platform} from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";
import { RoutesService } from "../../../services/routes.service";
import { SheetState } from "ng-bottom-sheet";
import { NotifierService } from "../../../services/notify.service";
import { MatDialog } from "@angular/material/dialog";
import { EventManager } from "@angular/platform-browser";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit, OnDestroy {

  @ViewChild('bottomSheet') bottomSheet: any;

  /**
   * notification text
   */
  public notification = "";

  /**
   * State for current component
   */
  private _isDestroyed = false;

  public isLoading = false;

  public route: BusEta.Route;

  private routes: BusEta.Route[];

  public traveller: BusEta.Traveller;

  public travellers: BusEta.Traveller[];

  public travellersFilter: BusEta.Traveller[];

  public routeId: string;

  public showList = false;

  public travellerId: string;

  public lastStopId: number;

  public travellerStopIdx: number;

  /**
   * hide/show bottom title
   */
  public hideBottomTitle = true;

  /**
   * Min height in px for bottom sheet
   */
  public bottomMinHeight = 80;

  public sheetState = SheetState.Bottom;

  /**
   * Disable "Docked" state for bottom sheet
   */
  public disableDockState = false;

  public topOffset = 50;

  public dockHeight;

  private _map: H.Map;

  public currentTime: Date = new Date();

  private _objects: {
    points?: H.map.DomMarker[];
    bus?: H.map.DomMarker;
    stop?: H.map.DomMarker;
    end?: H.map.DomMarker;
    route?: {
      completed?: H.map.Polyline;
      incompleted?: H.map.Polyline;
    };
  } = { points: [], route: {} };

  constructor(
    private _elementRef: ElementRef,
    private _mapService: MapService,
    private _auth: AuthService,
    private _firebase: FirebaseService,
    private _travellerService: TravellerService,
    private _routeService: RoutesService,
    private _notify: NotifierService,
    private _settingsService: SettingsService,
    public alertController: AlertController,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _changeRef: ChangeDetectorRef,
    private _firebaseService: FirebaseService,
    private _dialog: MatDialog,
    private platform: Platform,
    private eventManager: EventManager
  ) {

    this.dockHeight = window.innerHeight < 500 ? window.innerHeight : window.innerHeight * 70 / 100;

    // subscribe to route
    this._activeRoute.params.subscribe(params => {
      // check for params
      if(Object.keys(params).length > 0) {
        this.travellerId = params.travellerId;
        this.routeId = params.routeId;
      }
    });
  }

  ngOnInit() {

    // check available height for bottomsheet
    this.platform.ready().then(() => {
        window.addEventListener('resize', this.onWindowResize);
    });

    this.init();
  }

    ngOnDestroy() {
      this._isDestroyed = true;
      window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize = () => {
      this.dockHeight = window.innerHeight < 500 ? window.innerHeight : window.innerHeight * 70 / 100;
    };

  private async init() {
    try {
      const container = this._elementRef.nativeElement.getElementsByClassName("map").item(0) as HTMLElement;
      this._map = this._mapService.createMap(container);

      this._mapService.resizeMap(this._map, container);

      // subscribe to firebase notifications
      this._firebase.getEvents().subscribe((options) => {
        this.showNotify(options as BusEta.FirebaseEvent);
      });

      // initalize feed
      this.initFeed();

      // turn on notification
      this.checkSetting();
    }
    catch (err) {
      this._notify.error(`Initalization failed`, err);
    }
  }

  /*
  *  Turn On Notification
  * */
  private async checkSetting() {
    try {
      const settings = await this._settingsService.get();
      if(!settings.notifyPush) {
        const alert = await this.alertController.create({
          cssClass: 'notify-dialogue',
          header: 'Turn On Notification',
          message: 'To receive notifications on the bus arrival eta, Go to the Settings and enable notifications',
          buttons: [{
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'cancelBtn',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }, {
            text: 'Settings',
            cssClass: 'settingBtn',
            handler: () => {
              this._router.navigate(["/main/settings"]);
            }
          }]
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
      }
    }
    catch (err) {
      this._notify.error(`Get Settings Failed`, err);
    }
  }

  private async initFeed() {

    try {

      this.isLoading = true;

      // fetch travellers and routes
      this.travellers = await this._travellerService.find();
      this.travellersFilter = [...this.travellers];
      this.routes = await this._routeService.find();

      if(this.travellers.length === 0 || this.routes.length === 0) {
        return;
      }

      // init with first item
      if(!this.travellerId) {
        this.travellerId = this.travellers[0]._id;
      }
      this.traveller = this.travellers.find(r => r._id === this.travellerId);

      // no routes
      if(!this.traveller.routes) {
        return;
      }

      if(!this.routeId) {
        this.routeId = this.traveller.routes[0].routeId;
      }
      this.route = this.routes.find(r => r._id === this.routeId);

      // find stopIndex
      const stopId = this.traveller.routes.find(r => r.routeId === this.route._id).stopId;
      this.travellerStopIdx = this.route.stops.findIndex(s => s.id === stopId);

      // Run Feed
      this.runFeed(this.travellerId, this.routeId);

    }
    catch (err) {
      console.error(err);
      this._notify.error(`Initalization failed`, err);
    } finally {
      this.isLoading = false;
      const dragIcon: HTMLElement | null = this._elementRef.nativeElement.querySelector('#drag-icon');
      if(dragIcon) {
        this.eventManager.addEventListener(dragIcon, 'touch', this.openSheet.bind(this));
        this.eventManager.addEventListener(dragIcon, 'click', this.openSheet.bind(this));
      }
    }
  }

  private showNotify(options: BusEta.FirebaseEvent) {
    // put some logig to handel text
    this.notification = options.text;
    // manually trigger change detection
    this._changeRef.detectChanges();
  }

  /**
   * run infinity feed for status update
   */
  private async runFeed(travellerId: string, routeId: string) {

    let isFirstRun = true;

    while (true) {
      try {

        console.log('Bus Progress', this._isDestroyed);

        // return if no state
        if (this._isDestroyed) {
          return;
        }

        const state = await this._travellerService.getCurrentState(travellerId, routeId);
        if(!state) {
          continue;
        }

        // no stops don't create path
        if(this.route.stops.length === 0) {
          this.createBusMarker(state);
          this._mapService.zoomTo(this._map, [state.bus.location]);
          continue;
        }

        this.lastStopId = state.lastStopId;

        // update map
        this.updateMap(state);

        // zoom for first run
        if (isFirstRun) {
          this.zoom(state);
          isFirstRun = false;
        }
      }
      catch (err) {
        console.error(`Feed failed:`, err);
      }
      finally {
        // sleep 10 sec before next update
        await this.sleep(10000);
      }
    }
  }

  private sleep(timeout: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  }

  /**
   * Update map
   */
  private updateMap(state: BusEta.TravellerState) {

    this.createBusMarker(state);

    this.createStopMarker(state);

    this.createEndMarker(state);

    this.createStopsPoint();

    this.createRoute(state);
  }

  private zoom(state: BusEta.TravellerState) {
    // zoom to route
    this._mapService.zoomTo(this._map, [state.bus.location, state.stop.location, state.end.location]);
  }

  private createRoute(state: BusEta.TravellerState) {

    if (state.route.incompleted.length >= 2) {
      // add incompleted route
      //
      const incompletePath = new H.geo.LineString();
      for (const p of state.route.incompleted) {
        incompletePath.pushLatLngAlt(p.lat, p.lng, 0);
      }

      if (this._objects.route.incompleted) {
        this._objects.route.incompleted.setGeometry(incompletePath);
      }
      else {
        this._objects.route.incompleted = new H.map.Polyline(incompletePath, {
          style: {
            fillColor: "#828282",
            strokeColor: "#828282",
            lineWidth: 5
          }
        });
        this._map.addObject(this._objects.route.incompleted);
      }
    }
    else {
      // remove line from map
      if (this._objects.route.incompleted) {
        this._map.removeObject(this._objects.route.incompleted);
        this._objects.route.incompleted = null;
      }
    }


    if (state.route.completed.length >= 2) {
      // add completed route
      //
      const completePath = new H.geo.LineString();
      for (const p of state.route.completed) {
        completePath.pushLatLngAlt(p.lat, p.lng, 0);
      }

      if (this._objects.route.completed) {
        this._objects.route.completed.setGeometry(completePath);
      }
      else {
        this._objects.route.completed = new H.map.Polyline(completePath, {
          style: {
            fillColor: "#9E21D9",
            strokeColor: "#9E21D9",
            lineWidth: 5
          }
        });
        this._map.addObject(this._objects.route.completed);
      }
    }
    else {
      // remove line from map
      if (this._objects.route.completed) {
        this._map.removeObject(this._objects.route.completed);
        this._objects.route.completed = null;
      }
    }
  }


  private createEndMarker(state: BusEta.TravellerState) {
    const pos = new H.geo.Point(state.end.location.lat, state.end.location.lng);
    const domIcon = new H.map.DomIcon(
      `<div class="end-wrapper">` +
      `<div class="end-icon"></div>` +
      `</div>`
    );
    if (this._objects.end) {
      this._objects.end.setIcon(domIcon);
    }
    else {
      this._objects.end = new H.map.DomMarker(pos, {
        icon: domIcon
      });
      this._map.addObject(this._objects.end);
    }
  }

  private createStopMarker(state: BusEta.TravellerState) {
    const pos = new H.geo.Point(state.stop.location.lat, state.stop.location.lng);
    const domIcon = new H.map.DomIcon(
      `<div class="stop-wrapper">` +
      `<div class="stop-point"></div>` +
      `<div class="stop-icon"></div>` +
      `</div>`
    );
    if (this._objects.stop) {
      this._objects.stop.setIcon(domIcon);
    }
    else {
      this._objects.stop = new H.map.DomMarker(pos, {
        icon: domIcon
      });
      this._map.addObject(this._objects.stop);
    }
  }

  private createStopsPoint() {

    // create points for all stops
    for (const stop of this.route.stops) {
      const stopIdx = this.route.stops.findIndex(s => s.id === stop.id);
      const pos = new H.geo.Point(stop.location.lat, stop.location.lng);
      const domIcon = new H.map.DomIcon(
          `<div class="stop-wrapper">` +
          `<div class="${stopIdx <= this.lastStopId ? `p_point`: `s_point`}"></div>` +
          `</div>`
      );
      // check if stop point exists
      if (this._objects.points[stopIdx]) {
        this._objects.points[stopIdx].setIcon(domIcon);
      }
      else {
        this._objects.points[stopIdx] = new H.map.DomMarker(pos, {
          icon: domIcon
        });
        this._map.addObject(this._objects.points[stopIdx]);
      }
    }
  }

  private createBusMarker(state: BusEta.TravellerState) {
    if (!state.bus || !state.bus.location) {
      return;
    }
    const pos = new H.geo.Point(state.bus.location.lat, state.bus.location.lng);
    const rotation = `transform: rotate(${state.bus.heading}deg);`;
    let stateClass = state.state == "in_bus" ? "in-bus" : "out-bus";

    let display = 'block';
    if(!state.nfc.trim()) {
      display = 'none';
      stateClass = 'no-nfc';
    }

    const domIcon = new H.map.DomIcon(
      `<div class="bus-wrapper">` +
      `<div class="bus-marker" style="${rotation}"></div>` +
      `<div class="bus-icon" style="display: ${display}"></div>` +
      `<div class="bus-state ${stateClass}"></div>` +
      `</div>`
    );

    if (this._objects.bus) {
      this._objects.bus.setIcon(domIcon);
      this._objects.bus.setGeometry(pos);
    }
    else {
      this._objects.bus = new H.map.DomMarker(pos, {
        icon: domIcon,
        zIndex: 10
      });
      this._map.addObject(this._objects.bus);
    }
  }

  public toggleSearch(e: Event) {
    e.preventDefault();
    this._router.navigate(["/main/search-routes"]);
  }

  // getStopArrivalTime
  public getStopArrivalTime(index: number) {
      let arrivalTime = this.currentTime.getTime();
      for (let i=1; i <= index; i++) {
        const stop = this.route.stops[i];
        arrivalTime = arrivalTime + stop.driveTime + stop.stopTime;
      }
      return arrivalTime;
  }

  public onBottomStateChange() {
  }

  public openSheet() {
    if(this.route.stops.length !== 0) {
      this.sheetState =  this.sheetState === SheetState.Bottom ? SheetState.Docked: SheetState.Bottom;
    }
  }

}
