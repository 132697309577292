<app-login-header [title]="'Verify OTP'" [customerId]="customerId"></app-login-header>

<ion-content>
    <form  #myForm="ngForm">
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1 class="title">Please enter the OTP sent to</h1>
            <div class="blue-title">{{phone}}</div>
          </ion-col>
        </ion-row>
        <ion-row>
            <ion-col *ngFor="let cell of digits; let i = index;">
              <ion-input type="number"
                         placeholder="0"
                         name="{{'digit'+i}}"
                         maxlength="1" tabindex="1"
                         #inputs
                         firefocusevents="true"
                         (ionInput)="gotoNextField($event, i)"
                         (click)="clearInput($event, i)"
                         [ngModel]="cell"></ion-input>
            </ion-col>
          </ion-row>
        <ion-row class="resend">
          <ion-col>
            <a (click)="requestCode()">Resend OTP</a>
          </ion-col>
        </ion-row>
<!--
        <ion-row>
          <ion-col class="login">
            <ion-button (click)="verifyCode(myForm)" expand="block">Continue</ion-button>
          </ion-col>
        </ion-row>
-->
      </ion-grid>
    </form>
</ion-content>
