import { Injectable } from '@angular/core';
import { BusEta } from '../app';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(
        private _http: HttpService
    ) { }

    public async find(): Promise<BusEta.Notification[]> {
        // get last 100 notifications
        return await this._http.post("/notifications/find", {
            skip: 0,
            limit: 100
        });
    }

    public async markAsViewed(ids: string[]): Promise<void> {
        // mark notification as viewed
        return await this._http.post("/notifications/viewed", { ids: ids });
    }

    public async clear(): Promise<BusEta.Notification[]> {
        // clear all notifications
        return await this._http.post("/notifications/clear");
    }

}
