import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusEta } from 'src/app/app';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthService } from "../../services/auth.service";
import { NotifierService } from "../../services/notify.service";

@Component({
  selector: 'app-select-bus',
  templateUrl: './select-bus-service.page.html',
  styleUrls: ['./select-bus-service.page.scss']
})
export class SelectBusServicePage implements OnInit {

  public services: Array<BusEta.AuthData>;

  public servicesBackup: Array<BusEta.AuthData>;

  public ishidden = true;

  constructor(
    private _customerService: CustomerService,
    private _activedRouter: ActivatedRoute,
    private _router: Router,
    private _notify: NotifierService,
    private _auth: AuthService
  ) { }

  ngOnInit() {
    this.getCustomers();
  }

  public filterList(evt) {
    this.services = this.servicesBackup;
    const searchTerm = evt.target.value;

    if (!searchTerm) {
      return;
    }

    this.services = this.services.filter(service => {
      if (service.database && searchTerm) {
        return (service.database.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
      }
    });
  }

  public toggleMenu() {
    this.ishidden = !this.ishidden;
  }

  public async continue(database: string) {
    try {
      this.ishidden = true;
      await this._auth.loginWithDatabase(database);
      this._router.navigate(["/main/map/bus"]);
    }
    catch (err) {
      this._notify.error(err);
    }
  }

  public async getCustomers() {
    try {
      if(!this._auth.dbsAuthData) {
        return;
      }
      this.services = this._auth.dbsAuthData;
      this.servicesBackup = this.services.slice(0);
    }
    catch (err) {
      this._notify.error(err);
    }
  }

}
