import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BusEta } from 'src/app/app';
import { NotifierService } from "../../../services/notify.service";
import { Router } from "@angular/router";
import { PublicNotificationsService } from "../../../services/public.notifications.service";
import { Preferences } from "@capacitor/preferences";

@Component({
    selector: 'app-banners',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, OnDestroy {

    private prefix = "bus.eta.";

    public banners: BusEta.PublicBanner[] = [];

    public isLoading = false;

    @Input() userId: string;

    private _isDestroyed = false;

    constructor(
        private _publicNotificationsService: PublicNotificationsService,
        private _notify: NotifierService,
        private _router: Router
    ) { }

    ngOnInit() {
        this.init();
    }

    ngOnDestroy(): void {
        this._isDestroyed = true;
    }

    private async init() {
        try {
            this.isLoading = true;
            this.initFeed(this.userId);
            this.autoCloseBanner();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public async initFeed(userId: string) {
        while (true) {
            try {
                if (this._isDestroyed) {
                    return;
                }
                console.log('fetching banner');
                this.banners = [];
                const banners = await this._publicNotificationsService.getBanners(userId);
                const storageBanners = await this.getBannersStorage();
                for (const banner of banners) {
                    const bannerState = storageBanners.find(b => b.id === banner.routeId);
                    if (bannerState && bannerState.expiry > new Date().getTime()) {
                        continue;
                    }
                    this.banners.push(banner);
                }
            }
            catch (err) {
                console.error(`Feed failed:`, err);
            }
            finally {
                // sleep 1 min 
                await this.sleep(1000 * 60 * 1);
            }
        }
    }

    public async autoCloseBanner() {
        while (true) {
            if (this._isDestroyed) {
                return;
            }
            try {
                console.log('auto closing banner', this.banners.length);
                for (const banner of this.banners) {
                    await this.closeBanner(banner.routeId);
                }
            }
            catch (err) {
                console.error(`Feed failed:`, err);
            }
            finally {
                // close in 10 min 
                await this.sleep(1000 * 60 * 10);
            }
        }
    }

    private sleep(timeout: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, timeout);
        });
    }

    public async openBanner(banner: BusEta.PublicBanner) {
        await this.closeBanner(banner.routeId);
        this._router.navigate([
            `/main/public/map/${banner.database}`,
            {
                routeId: banner.routeId
            }
        ]);
    }

    public async closeBanner(routeId: string) {

        // set banner expiry for specific subscribed stop id
        const expiryDate = new Date();
        expiryDate.setHours(expiryDate.getHours() + 24);


        // get banners data
        let data = await this.getBannersStorage();

        // delete previous items assigned to specific route
        data = data.filter(i => i.id != routeId);

        // add new item
        data.push({
            id: routeId,
            expiry: expiryDate.getTime()
        });

        // save banner data to local storage
        await Preferences.set({ key: this.prefix + "bannerData", value: JSON.stringify(data) });
        this.banners = this.banners.filter(b => b.routeId !== routeId);
    }

    public async getBannersStorage(): Promise<{ id: string; expiry: number }[]> {
        // get banner data from local storage
        let bannerData = await Preferences.get({ key: this.prefix + "bannerData" });
        if (bannerData && bannerData.value) {
            return JSON.parse(bannerData.value);
        }
        return [];
    }

}
