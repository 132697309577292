import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusEta } from 'src/app/app';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthService } from "../../services/auth.service";
import { NotifierService } from "../../services/notify.service";

@Component({
  selector: 'app-select-public-service',
  templateUrl: './select-public-service.page.html',
  styleUrls: ['./select-public-service.page.scss']
})

export class SelectPublicServicePage implements OnInit {

  public services: Array<BusEta.Customer>;

  public servicesBackup: Array<BusEta.Customer>;

  public ishidden = true;

  public isLoading = true;

  constructor(
    private _customerService: CustomerService,
    private _activedRouter: ActivatedRoute,
    private _router: Router,
    private _notify: NotifierService,
    private _auth: AuthService
  ) { }

  ngOnInit() {
    this.getPublicCustomers();
  }

  public filterList(evt) {
    this.services = this.servicesBackup;
    const searchTerm = evt.target.value;

    if (!searchTerm) {
      return;
    }

    this.services = this.services.filter(service => {
      if (service.name && searchTerm) {
        return (service.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
      }
    });
  }

  public toggleMenu() {
    this.ishidden = !this.ishidden;
  }

  public async continue(record: { id: string; name: string }) {
    try {
      this._router.navigate([`/main/public/map/${record.name}`]);
    }
    catch (err) {
      this._notify.error(err);
    }
  }

  public async getPublicCustomers() {
    try {
      this.isLoading = true;
      this.services = await this._customerService.getList();
      this.servicesBackup = this.services.slice(0);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }
}
