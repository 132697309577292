import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MapService {

    private _platform: H.service.Platform;

    constructor() {
        this._platform = new H.service.Platform({
            apikey: environment.hereMaps
        });
    }

    public createMap(container: HTMLElement) {
        const layers = this._platform.createDefaultLayers();

        const map = new H.Map(
            container,
            layers.raster.normal.map,
            {
                zoom: 3,
                center: { lat: 34.366, lng: -89.519 }
            });

        // Create the default UI:
        // const ui = H.ui.UI.createDefault(map, layers);

        // MapEvents enables the event system
        // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

        // Attach to window event
        this.attachToResizeEvent(map, container);

        return map;
    }

    private attachToResizeEvent(map: H.Map, element: HTMLElement) {
        window.addEventListener("resize", () => {
            setTimeout(() => {
                this.resizeMap(map, element);
            }, 1);
        });
        window.addEventListener("orientationchange", () => {
            setTimeout(() => {
                this.resizeMap(map, element);
            }, 1);
        });
    }

    public resizeMap(map: H.Map, element: HTMLElement): Promise<any> {
        return new Promise((resolve) => {
            if (element.clientWidth > 0 &&
                element.clientHeight > 0) {
                map.getViewPort().resize();

                setTimeout(() => {
                    resolve(null);
                }, 100)
                return;
            }

            let timer = setInterval(() => {
                if (element.clientWidth > 0 &&
                    element.clientHeight > 0) {
                    clearInterval(timer);

                    map.getViewPort().resize();

                    setTimeout(() => {
                        resolve(null);
                    }, 100)
                    return;
                }
            }, 1000)
        })
    }


    /**
     * zoom to extend by specific points
     */
    public zoomTo(map: H.Map, pnts: Array<{ lat: number, lng: number }>) {
        if (!pnts || pnts.length == 0) {
            return;
        }
        if (pnts.length == 1) {
            map.setZoom(15);
            map.setCenter(new H.geo.Point(pnts[0].lat, pnts[0].lng));
            return;
        }

        const latLngs = [];
        pnts.forEach(p => latLngs.push(p.lat, p.lng, 0));

        const bounds = H.geo.Rect.coverLatLngAlts(latLngs) as H.geo.Rect;

        const minSize = 0.001;

        if (bounds.getWidth() < minSize &&
            bounds.getHeight() < minSize) {
            map.setCenter(bounds.getCenter());
            map.setZoom(17);
        }
        else {
            const p1 = bounds.getBottomRight();
            const p2 = bounds.getTopLeft();
            const width = Math.max(bounds.getWidth(), minSize);
            const height = Math.max(bounds.getHeight(), minSize);

            // add offset for zooming
            const targetBox = H.geo.Rect.coverLatLngAlts(
                [
                    p1.lat - (height * 0.15),
                    p2.lng - (width * 0.15),
                    0,
                    p2.lat + (height * 0.15),
                    p1.lng + (width * 0.15),
                    0
                ]
            ) as H.geo.Rect;

            map.getViewPort().resize();
            map.getViewModel().setLookAtData({ bounds: targetBox });
        }
    }

}
