import { environment } from "src/environments/environment";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { BusEta } from "../app";
import { Preferences } from "@capacitor/preferences";
import { Device } from '@capacitor/device';
import AuthData = BusEta.AuthData;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private prefix = "bus.eta.";

    public token: string;

    public authData: BusEta.AuthData;

    public dbsAuthData: BusEta.AuthData[];

    private subject = new Subject<Object>();

    constructor(
        private _http: HttpClient) {
    }

    public async initalize() {
        try {
            const tokenVal = await Preferences.get({ key: this.prefix + "token" });
            this.token = tokenVal ? tokenVal.value : "";
        }
        catch (err) {
            console.error(err);
        }
    }

    public async requestCode(phone: string): Promise<any> {
        await this._http.post(`${environment.service}/auth/requestCode`, { phone: phone }).toPromise();
    }

    public async loginWithPhone(phone: string, code: string): Promise<any> {
        let data = await this._http.post(`${environment.service}/auth/authWithCode`, { phone: phone, code: code })
            .toPromise() as AuthData[];
        this.dbsAuthData = data;
    }

    public async loginWithDatabase(database: string): Promise<any> {
        const data = this.dbsAuthData.find(d => d.database === database);
        console.log('selected db data', data);
        await Preferences.set({ key: this.prefix + "token", value: data.token });
        await Preferences.set({ key: this.prefix + "authdata", value: JSON.stringify(data) });
        // get auth data
        this.authData = data;
        this.token = this.authData.token;
    }

    public async loginWithUserId(): Promise<string> {

        let userId = await Preferences.get({ key: this.prefix + "user_id" });
        if (userId && userId.value) {
            return userId.value;
        }

        // get device id and use it as user id
        const deviceIdRes = await Device.getId();
        await Preferences.set({ key: this.prefix + "user_id", value: deviceIdRes.uuid });
        return deviceIdRes.uuid;
    }

    private async finalize(data: { token: string }) {
        let token: string = data.token;
        if (!token) {
            throw "Auth failed";
        }
        this.token = token;

        // get auth data
        this.authData = await this._http.post(`${environment.service}/auth/verify`, { token: token })
            .toPromise() as BusEta.AuthData;

        await Preferences.set({ key: this.prefix + "token", value: token });
        await Preferences.set({ key: this.prefix + "authdata", value: JSON.stringify(this.authData) });

        // send event
        this.subject.next({ action: "initalized" });
    }

    public async verify() {
        if (!this.token) {
            return false;
        }

        this.authData = await this._http.post(`${environment.service}/auth/verify`, { token: this.token })
            .toPromise() as BusEta.AuthData;

        this.finalize({ token: this.token });
    }

    public async logout() {
        this.token = "";

        await Preferences.set({ key: this.prefix + "token", value: "" });
        await Preferences.set({ key: this.prefix + "authdata", value: "" });
    }

    /**
     * Authorize with zenduone token and oauth parameters
     *
     * @param accessToken zenduone access token
     * @param options oauth parameters (grant code flow)
     */
    public async ssoAuthorize(
        accessToken: string,
        options: {
            client_id: string;
            redirect_uri: string;
            response_type: string;
            scope: string;
        }): Promise<{ url: string }> {


        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: accessToken
            })
        };

        const query = Object.keys(options).map(k => `${k}=${options[k]}`).join("&");
        const data = await this._http.post(
            `${environment.service}/oauth/v2/authorize?${query}`, {}, httpOptions)
            .toPromise() as { url: string };
        if (!data) {
            throw "auth failed";
        }
        if (!data.url) {
            throw "redirect url is empty";
        }
        return data;
    }

    public getEvents() {
        return this.subject.asObservable();
    }
}
