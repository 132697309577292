import { Component, OnInit } from '@angular/core';
import { BusEta } from 'src/app/app';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotifierService } from "../../../services/notify.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { PublicNotificationsService } from "../../../services/public.notifications.service";
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {

    public newNotifications = 0;

    public notifications: BusEta.Notification[] = [];

    public isLoading = false;

    public guest = false;

    public filterType: "all" | "tag" | "bus" = "all";

    private _loadedNotifications: BusEta.Notification[] = [];

    constructor(
        private _noticationsService: NotificationsService,
        private _publicNotificationsService: PublicNotificationsService,
        private _notify: NotifierService,
        private _activeRoute: ActivatedRoute,
        private _auth: AuthService,
        private _router: Router
    ) {
        this.guest = this._activeRoute.snapshot.parent.url.length > 0;
    }

    public async ngOnInit() {
        this._router.events.subscribe(async event => {
            if (event instanceof NavigationEnd && (event.url === `/main/notify` || event.url === `/main/public/notify`)) {
                await this.init();
            }
        });
    }

    private async init() {
        try {
            this.isLoading = true;

            await this.load();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    private async load() {
        if (this.guest) {
            const userId = await this._auth.loginWithUserId();
            this._loadedNotifications = await this._publicNotificationsService.find(userId);
        }
        else {
            // load notification
            this._loadedNotifications = await this._noticationsService.find();
        }

        // mark loaded notifications as viewed
        const notificationIds = this._loadedNotifications.filter(n => !n.viewed).map(n => n._id);
        await this._noticationsService.markAsViewed(notificationIds);

        this.filterItems();
    }

    public async doRefresh(event) {
        try {
            // reload notifications
            await this.load();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            event.target.complete();
        }
    }



    public setFilter(mode: "all" | "tag" | "bus") {
        this.filterType = mode;
        this.filterItems();
    }

    private filterItems() {
        if (this.filterType == "all") {
            this.notifications = this._loadedNotifications.slice(0);
        }
        else {
            this.notifications = this._loadedNotifications.filter(n => this.getNotificationType(n) === this.filterType);
        }


        this.newNotifications = 0;
        for (const notification of this.notifications) {
            if (!notification.viewed) {
                this.newNotifications++;
            }
        }
    }

    private getNotificationType(notification: BusEta.Notification) {
        if (notification.text.includes("boarded ") ||
            notification.text.includes("exited ") ||
            notification.text.includes(" tag ")) {
            return "tag";
        } {
            return "bus";
        }
    }

    public getTime(notification: BusEta.Notification) {
        if (!notification.createdAt) {
            return "N/A";
        }
        const durationMin = Math.round((Date.now() - new Date(notification.createdAt).getTime()) / 60000);
        if (durationMin > 24 * 60) {
            return `${Math.floor(durationMin / (24 * 60))}d`;
        }
        else if (durationMin > 60) {
            return `${Math.floor(durationMin / (60))}h`;
        }
        else {
            return `${Math.floor(durationMin)}m`;
        }
    }

    public getNotificationTypeLabel(notification: BusEta.Notification) {
        const type = this.getNotificationType(notification);
        if (type == "tag") {
            return "Tag";
        }
        else {
            return "Bus Activity";
        }
    }
}
