<ion-toolbar>
  <div class="routeSearch">
    <ion-searchbar
            color="none"
            id="search"
            (ionChange)="search($event)"
            placeholder="Search Route"
            search-icon="assets/svg/back.svg">
    </ion-searchbar>
  </div>
</ion-toolbar>
<ion-content>
  <ion-list>
    <ion-item lines="none">
      <ion-label>Recent</ion-label>
    </ion-item>
  </ion-list>
  <ion-list *ngFor="let traveller of travellersFilter;">
    <ion-item
            class="route-item"
            (click)="selectRoute(route.id, traveller._id)"
            *ngFor="let route of traveller.readonly.routes; let i = index">
      <ion-icon src="assets/svg/route.svg"></ion-icon>
      <ion-label>{{ traveller.readonly.routes[i]?.routeName | titlecase }} ({{ traveller.name }})</ion-label>
    </ion-item>
  </ion-list>
  <ion-list>
    <ion-item lines="none">
      <ion-label>
        <div *ngIf="isLoading" class="loading-wrapper">
          <div class="text">
            Loading...
          </div>
          <ion-progress-bar type="indeterminate"></ion-progress-bar>
        </div>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
