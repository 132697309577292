<ion-header>
    <!-- Customized -->
    <div class="header-bar"
         [ngStyle]="{'background-color': background }">
        <ion-buttons slot="start">
            <ion-back-button text=""
                             slot="start"
                             class="toolbar-icon"
                             [icon]="'chevron-back-outline'"
                             defaultHref="select-bus-service"
                             mode="md">
            </ion-back-button>
        </ion-buttons>
        <img height="40"
             [src]="logo"
             alt="img" />
    </div>

</ion-header>

<div class="main">

    <ion-tabs>

        <ion-tab-bar slot="bottom">
            <ion-tab-button tab="map">
                <ion-icon name="map-sharp"></ion-icon>
                <ion-label>Map</ion-label>
            </ion-tab-button>

            <ion-tab-button tab="notify">
                <ion-icon name="notifications-sharp"></ion-icon>
                <ion-label>Notifications</ion-label>
            </ion-tab-button>

            <ion-tab-button *ngIf="!guest"
                            tab="settings">
                <ion-icon name="settings-sharp"></ion-icon>
                <ion-label>Settings</ion-label>
            </ion-tab-button>
        </ion-tab-bar>

    </ion-tabs>

</div>
