import { Injectable } from "@angular/core";
import { BusEta } from '../app';
import { HttpService } from "./http.service";

@Injectable()
export class RoutesService {

    constructor(private _http: HttpService) { }

    public async find(): Promise<BusEta.Route[]> {
        return await this._http.post("/routes/find") as BusEta.Route[];
    }

    public async add(route: BusEta.Route): Promise<string> {
        const res = await this._http.post("/routes/add", route) as { id: string };
        return res.id;
    }

    public async update(route: BusEta.Route) {
        await this._http.post("/routes/update", route);
    }

    public async delete(id: string) {
        await this._http.post("/routes/delete", { id: id });
    }
}
