import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingController } from "@ionic/angular";
import { NgForm } from "@angular/forms";
import { NotifierService } from "../../services/notify.service";
import { isNumeric } from "rxjs/internal-compatibility";

@Component({
  selector: 'app-login-verify-code',
  templateUrl: './login-verify-code.page.html',
  styleUrls: ['./login-verify-code.page.scss'],
})
export class LoginVerifyCodePage implements OnInit {

  @ViewChildren('inputs') inputs: QueryList<HTMLIonInputElement>;

  @ViewChild('myForm') public form: NgForm;

  public customerId: string;

  public phone: string;

  public digits: string[] = ["", "", "", ""];

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _loadingController: LoadingController,
    private _notifierService: NotifierService
  ) {
    this._activeRoute.params.subscribe(params => {
      this.phone = params.phone;
      this.customerId = params.customerId;
    });
  }

  ngOnInit() {
  }

  public gotoNextField(evt, index: number) {

    const currentInput = this.inputs.toArray()[index];

    // return if input is not number
    if(!isNumeric(currentInput.value)){
      return false;
    }

    if (index >= this.digits.length-1) {
      this.verifyCode(this.form);
    } else {
      const nextInput = this.inputs.toArray()[index+1];
      nextInput.value =  ``;
      nextInput.setFocus();
    }

  }
  
  public clearInput(evt, index: number) {
    const currentInput = this.inputs.toArray()[index];
    currentInput.value = ``;
  }

  public continue() {
    this._router.navigate(["/login-success"]);
  }

  public async requestCode() {
    const loading = await this._loadingController.create({
      message: 'Please wait...'
    });
    try {
      await loading.present();
      const phone = this.phone;
      await this._auth.requestCode(phone);
      await loading.dismiss();
    } catch (err) {
      await loading.dismiss();
      this._notifierService.error(err);
    }
  }

  public async verifyCode(myForm) {
    const loading = await this._loadingController.create({
      message: 'Please wait...'
    });
    try {
      await loading.present();
      const code = Object.values(myForm.value).join("");
      const phone = this.phone;
      await this._auth.loginWithPhone(phone, code);
      await loading.dismiss();
      if(this._auth.dbsAuthData.length > 1) {
        this._router.navigate(["/select-bus-service"], { queryParams: { busType: "School Bus" } });
        return;
      }
      // if just one database get into login
      await this._auth.loginWithDatabase(this._auth.dbsAuthData[0].database);
      this._router.navigate(["/main/map/bus"]);
    } catch (err) {
      await loading.dismiss();
      this._notifierService.error(err);
    }
  }
}
