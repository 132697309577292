<app-login-header [title]="'Login'" [customerId]="customerId"></app-login-header>

<ion-content>
    <ion-grid>
      <ion-row>
        <ion-col offset="4">
          <ion-icon [src]="'assets/icon/tick.svg'"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div class="title">Verified Successfully</div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="login">
          <ion-button (click)="continue()" expand="block">Continue</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
</ion-content>
