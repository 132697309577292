import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guard/auth.guard';

import { MainPage } from './main.page';
import { MapComponent } from './map/map.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SettingsComponent } from './settings/settings.component';
import { SearchRoutesComponent } from "./map/search-routes/search-routes.component";
import { PublicRoutesComponent } from "./public/map/search-routes/search-routes.component";
import { SharedPageComponent } from './public/shared-page/shared-page.component';
import { PublicMapComponent } from './public/map/map/map.component';

const routes: Routes = [
  {
    path: '',
    component: MainPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'map/bus',
        component: MapComponent
      },
      {
        path: 'search-routes',
        component: SearchRoutesComponent
      },
      {
        path: 'notify',
        component: NotificationsComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: '',
        redirectTo: '/main/map/bus',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'public',
    component: MainPage,
    children: [
      {
        path: 'map/:database',
        component: PublicMapComponent
      },
      {
        path: 'routes',
        component: PublicRoutesComponent
      },
      {
        path: 'notify',
        component: NotificationsComponent
      },
      {
        path: '',
        redirectTo: '/main/public',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'shared',
    component: SharedPageComponent,
    children: [
      {
        path: 'map/:database',
        component: PublicMapComponent
      },
      {
        path: 'routes',
        component: PublicRoutesComponent
      },
      {
        path: '',
        redirectTo: '/main/shared',
        pathMatch: 'full'
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})

export class MainPageRoutingModule { }
