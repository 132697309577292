import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UIService {
    public isSmallScreen(): boolean {
        return window.innerWidth < 768;
    }
}
