<app-login-header [title]="'School Bus'"></app-login-header>
<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col>
                <h1>Welcome,</h1>
                <h1 class="blue-title">Select your bus service to continue</h1>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="dropdown">
                <div class="ion-select" (click)="toggleMenu()">Please Select Your Bus Service <ion-icon slot="end" class="drop-icon" [name]="ishidden ? 'chevron-down-outline': 'chevron-up-outline'"></ion-icon></div>
                <div [hidden]=ishidden class="dropdown-content">
                    <ion-searchbar (ionInput)="filterList($event)" placeholder="Search"></ion-searchbar>
                    <ion-list lines="none">
                        <ion-item lines="none" (click)="continue(service.database)" *ngFor="let service of services">{{service.database}}</ion-item>
                    </ion-list>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<div class="footer-logo">
  <img height="27" src="assets/zen.png"/>
</div>
