import { Injectable } from '@angular/core';
import { BusEta } from '../app';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class TravellerService {

  constructor(
    private _http: HttpService
  ) { }

  public async find(): Promise<BusEta.Traveller[]> {
    return await this._http.post("/travellers/current/find");
  }

  public async getCurrentState(travellerId: string, routeId: string): Promise<BusEta.TravellerState> {
    return await this._http.post("/travellers/getState", {
      id: travellerId,
      routeId: routeId
    });
  }

}
