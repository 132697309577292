<ion-toolbar>
  <div class="routeSearch">
    <ion-searchbar
            #searchBar
            color="none"
            id="search"
            (ionChange)="search($event)"
            placeholder="Search Route"
            search-icon="assets/svg/back.svg">
    </ion-searchbar>
  </div>
</ion-toolbar>
<ion-content>
  <ion-list>
    <ion-item lines="none">
      <ion-label class="lbl">Routes</ion-label>
    </ion-item>
  </ion-list>
  <ion-list *ngFor="let route of routesFilter;">
    <ion-item
            class="route-item"
            (click)="selectRoute(route._id.toString())">
      <ion-icon src="assets/svg/route.svg"></ion-icon>
      <ion-label>{{ route.name | titlecase }}</ion-label>
    </ion-item>
  </ion-list>
  <ion-list>
    <ion-item lines="none">
      <ion-label>
        <div *ngIf="isLoading" class="loading-wrapper">
          <div class="text">
            Loading...
          </div>
          <ion-progress-bar type="indeterminate"></ion-progress-bar>
        </div>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
