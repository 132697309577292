import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { InitalizePage } from './pages/initalize/initalize.page';
import { LoginEnterPhonePage } from './pages/login-enter-phone/login-enter-phone.page';
import { LoginSuccessPage } from './pages/login-success/login-success.page';
import { LoginVerifyCodePage } from './pages/login-verify-code/login-verify-code.page';
import { SelectBusServicePage } from './pages/select-bus-service/select-bus-service.page';
import { SelectServicePage } from './pages/select-service/select-service.page';
import { SelectPublicServicePage } from "./pages/select-public-service/select-public-service.page";
import * as mainModule from './pages/main/main.module';
import { RedirectComponent } from './pages/redirect/redirect.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'initalize',
        pathMatch: 'full'
    },
    {
        path: 'initalize',
        component: InitalizePage
    },
    {
        path: 'select-service',
        component: SelectServicePage
    },
    {
        path: 'select-bus-service',
        component: SelectBusServicePage
    },
    {
        path: 'select-public-service',
        component: SelectPublicServicePage
    },
    {
        path: 'login-verify-code',
        component: LoginVerifyCodePage
    },
    {
        path: 'login-enter-phone',
        component: LoginEnterPhonePage
    },
    {
        path: 'login-success',
        component: LoginSuccessPage
    },
    {
        path: 'map/:database',
        component: RedirectComponent
    },
    {
        path: 'main',
        loadChildren: async () => await mainModule.MainPageModule
    }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
