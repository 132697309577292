import { Component, Input, OnInit } from '@angular/core';
import { BusEta } from 'src/app/app';

@Component({
    selector: 'app-stops-list',
    templateUrl: './stops-list.component.html',
    styleUrls: ['./stops-list.component.scss'],
})
export class StopsListComponent implements OnInit {

    @Input() route!: BusEta.Route;

    @Input() state!: BusEta.PublicState;

    @Input() deviceId = "";

    constructor() { }

    ngOnInit() { }


    public stopDeviceVisible(stopId: string) {

        const stop = this.state.stops.find(s => s.stopId === stopId);

        if (!stop || !stop.lastDeviceId) {
            return false;
        }

        return this.deviceId ? this.getDeviceEta(stopId).lastDeviceId == this.deviceId: true;
    }

    public stopDeviceMoving(stopId: string) {

        // device visible for stop check if its moving
        if (!this.deviceId) {
            const stop = this.state.stops.find(s => s.stopId === stopId);
            return !stop.atStopNow;
        }

        const deviceEta = this.state.devicesEta.find(d => d.deviceId == this.deviceId);
        return deviceEta && deviceEta.state == "moving";
    }

    public getStopTime(stopId: string) {
        if (this.deviceId) {
            return this.getDeviceEta(stopId)?.time || 0;
        }
        return this.state.stops.find(s => s.stopId === stopId)?.time;
    }

    public isStopLate(stopId: string) {
        return this.state.stops.find(s => s.stopId === stopId)?.late;
    }

    public getDeviceEta(stopId: string) {
        if (!this.state.devicesEta) {
            return null;
        }
        const devEta = this.state.devicesEta.find(s => s.deviceId == this.deviceId);
        if (!devEta) {
            return null;
        }
        return devEta.stops.find(e => e.stopId == stopId);
    }    
}
