<div class="main">
    <div class="searchbar">
        <ion-searchbar
                class="ion-no-margin ion-no-padding"
                showCancelButton="focus"
                animated
                (ionFocus)="toggleSearch($event)"
                placeholder="Search Route"
        >
        </ion-searchbar>
        <div *ngIf="showList">
            <ion-list-header>Recent</ion-list-header>
            <ion-list *ngFor="let traveller of travellersFilter;">
                <ion-item
                        class="route-item"
                        *ngFor="let route of traveller.readonly.routes; let i = index">
                    <ion-icon src="assets/svg/route.svg"></ion-icon>
                    <ion-label>{{ route.routeName | titlecase }} ({{ traveller.name }})</ion-label>
                </ion-item>
            </ion-list>
        </div>
    </div>
    <div class="map-wrapper">
        <div class="loader" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <div class="map"></div>
        <div *ngIf="notification && route" class="notification-wrapper">
            {{notification}}
        </div>
    </div>
</div>
<ng-bottom-sheet
        *ngIf="route"
        [(state)]="sheetState"
        [enableScrollContent]="false"
        (stateChange)="onBottomStateChange()"
        [minHeight]="bottomMinHeight"
        [hideCloseButton]="true"
        [titleCentered]="true"
        [hideSeparator]="true"
        [title]="''"
        [hideTitle]="hideBottomTitle"
        titleSize="15px"
        [topDistance]="topOffset"
        [dockedHeight]="dockHeight"
        [disableDrag]="true"
        [useViewport]="false"
        [disableDockState]="disableDockState">

    <div>
        <ion-grid class="stop">
            <ion-row (click)="openSheet()" class="ion-justify-content-between divider ion-align-items-center">
                <ion-col size="8">
                    <div class="routeName">{{ route.name }} <span class="timer"> ({{route.stops.length}} stops)</span></div>
                    <div class="vehicleName">
                        {{this.route.device.name}}
                    </div>
                </ion-col>
                <ion-col class="inline ion-text-end ion-float-end ion-no-padding ion-no-margin" size="4">
                    <ion-chip class="ion-no-padding" color="no-color">
                        <ion-avatar>
                            <img src="assets/avatar.svg">
                        </ion-avatar>
                    </ion-chip>
                    <div class="tName ion-text-end">
                            {{this.traveller.name}}
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
        <ion-grid class="stop scroll">
          <ion-row class="ion-justify-content-between ion-align-items-center"
                   *ngFor="let stop of route.stops; let i = index">
            <ion-col size="1">
              <ion-chip [ngClass]="{'pass': i <= lastStopId }">
                <ion-label class="ion-no-padding ion-no-margin">{{i+1}}</ion-label>
              </ion-chip>
              <div *ngIf="i < route.stops.length-1"
                   class="timeline"
                   [ngClass]="{'pass': i <= lastStopId}"
              ></div>
            </ion-col>
            <ion-col size="8">
              <div class="stopName">
                <img *ngIf="i === travellerStopIdx"
                     [src]="lastStopId >= travellerStopIdx ? 'assets/pin_p.svg': 'assets/pin.svg'"
                >
                <img *ngIf="i === travellerStopIdx"
                     [src]="lastStopId >= travellerStopIdx ? 'assets/bus_p.svg': 'assets/bus.svg'"
                >
                <ion-label>{{stop.name}}</ion-label>
              </div>
            </ion-col>
            <ion-col size="3">
              <div class="timer ion-float-end">{{ getStopArrivalTime(i) |  date:'mediumTime' }}</div>
            </ion-col>
          </ion-row>
        </ion-grid>
    </div>

</ng-bottom-sheet>
