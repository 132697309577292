<ion-header>
  <ion-toolbar color="primary">
    <ion-title>ZenBus</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="loading-wrapper">
    <div class="text">
      Loading...
    </div>
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  </div>
</ion-content>