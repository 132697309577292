import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingController } from "@ionic/angular";
import { NotifierService } from "../../services/notify.service";

@Component({
  selector: 'app-login-enter-phone',
  templateUrl: './login-enter-phone.page.html',
  styleUrls: ['./login-enter-phone.page.scss'],
})
export class LoginEnterPhonePage implements OnInit {

  public phone: string

  public customerId: string;

  public countryCode = "+1";

  public busType: string;

  constructor(
    private _activedRouter: ActivatedRoute,
    private _auth: AuthService,
    private _router: Router,
    private _notify: NotifierService,
    private _loadingController: LoadingController
  ) {
    this.busType = this._activedRouter.snapshot.queryParams.busType;
  }

  ngOnInit() {
    this.customerId = this._activedRouter.snapshot.queryParams.id;
  }


  public async requestCode() {
    const loading = await this._loadingController.create({
      message: 'Please wait...'
    });
    try {
      await loading.present();
      const phone = this.countryCode + this.phone;
      await this._auth.requestCode(phone);
      await loading.dismiss();
      this._router.navigate(["/login-verify-code", { phone: phone }]);
    } catch (err) {
      await loading.dismiss();
      this._notify.error(err);
    }
  }

}
