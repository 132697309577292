<ion-header class="ion-no-border header"
            [ngStyle]="{'background-color': background }"
            *ngIf="isInitalized"></ion-header>
<div class="footer"
     *ngIf="isInitalized"
     [ngStyle]="{'background-color': background }">
    <img *ngIf="logo"
         [src]="logo"
         height="32"
         alt="img">
</div>

<ion-toolbar *ngIf="title">
    <ion-buttons slot="start">
        <ion-back-button slot="start"
                         type="primary"
                         class="arrow-back"
                         [icon]="'arrow-back'"
                         text="{{title}}"
                         defaultHref="select-service"
                         mode="md">
        </ion-back-button>
    </ion-buttons>
</ion-toolbar>
