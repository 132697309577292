import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusEta } from "../../../app";
import { NotifierService } from "../../../services/notify.service";
import { PublicFirebaseService } from "../../../services/public.firebase.service";
import { NotifyStopService } from "../../../services/notify.stop.service";
import { DatePipe } from "@angular/common";

export interface DialogData {
    id: string;
    routeId: string;
    stopId: string;
    mode: string;
}

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'app-notification-editor',
    templateUrl: 'notifyStop.dialogue.html',
    styleUrls: ['notifyStop.dialogue.scss']
})

export class NotifyStopComponent implements OnInit {

    @Output() dataSent = new EventEmitter<any>();

    public notifyStopSettings: BusEta.NotifyStop = {
        _id: '',
        startTime: {
            hr: 0,
            min: 0
        },
        endTime: {
            hr: 0,
            min: 0
        },
        routeId: "",
        stopId: "",
        userId: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    public startTime: string = new Date().toLocaleTimeString('en-US', {
        hour12: false,
        hour: 'numeric',
        minute: 'numeric'
    });

    public endTime: string;

    public isLoading = false;

    public mode: string;

    @Input() set Item(notifyStopSettings: BusEta.NotifyStop) {
        this.notifyStopSettings = notifyStopSettings;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialogRef: MatDialogRef<NotifyStopComponent>,
        private _notifyStopService: NotifyStopService,
        private _notifierService: NotifierService,
        private _firebaseService: PublicFirebaseService,
        private _datePipe: DatePipe,
    ) {
        this.notifyStopSettings.routeId = this.data.routeId;
        this.notifyStopSettings.stopId = this.data.stopId;
        this.notifyStopSettings._id = this.data.id;
        this.mode = this.data.mode;
    }

    async ngOnInit() { }

    public async save() {
        try {
            this.isLoading = true;
            this.notifyStopSettings.userId = this._firebaseService.userId;

            // convert start time to hr and min
            const startTime = this.startTime.split(":");
            this.notifyStopSettings.startTime = {
                hr: parseInt(startTime[0]),
                min: parseInt(startTime[1])
            };

            if (this.endTime) {
                // convert end time to hr and min
                const endTime = this.endTime.split(":");
                this.notifyStopSettings.endTime = {
                    hr: parseInt(endTime[0]),
                    min: parseInt(endTime[1])
                };
            } else {
                delete this.notifyStopSettings.endTime;
            }

            await this._notifyStopService.save(this.notifyStopSettings);
            this.mode = 'view';
            this.dataSent.emit();
        } catch (err) {
            this._notifierService.error(err);
        } finally {
            this.isLoading = false;
        }
    }

    public async remove() {
        try {
            this.isLoading = true;
            await this._notifyStopService.remove(this.notifyStopSettings._id);
            this.isLoading = false;
            this.mode = 'view';
            await this.dialogRef.close('confirm');
        } catch (err) {
            this._notifierService.error(err);
        }
    }

    public setCurrentTime() {
        this.startTime = new Date().toLocaleTimeString('en-US', {
            hour12: false,
            hour: 'numeric',
            minute: 'numeric'
        });
    }

    convertToAMPM(hours: number, minutes: number) {
        const time = new Date();
        time.setHours(hours);
        time.setMinutes(minutes);
        return this._datePipe.transform(time, 'h:mm a');
    }

}
