import { Injectable } from '@angular/core';
import { BusEta } from '../app';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PublicNotificationsService {

  constructor(
    private _http: HttpService
  ) { }

  public async find(userId: string): Promise<BusEta.Notification[]> {
    // get last 100 notifications
    return await this._http.post("/public/notifications/find", {
        userId: userId
    });
  }

  public async getBanners(userId: string): Promise<BusEta.PublicBanner[]> {
    return await this._http.post("/public/banner/find", {
      skip: 0,
      limit: 3,
      userId: userId
    });
  }
  
  public async clear(userId: string): Promise<BusEta.Notification[]> {
    // clear all notifications
    return await this._http.post("/public/notifications/clear", {
        userId: userId
    });
  }

}
