import { Injectable } from "@angular/core";
import { BusEta } from '../app';
import { HttpService } from "./http.service";

@Injectable()
export class PublicRoutesService {

    constructor(private _http: HttpService) { }

    public async find(database: string): Promise<BusEta.Route[]> {
        return await this._http.post("/public/routes/find", {
          database: database
        }) as BusEta.Route[];
    }

    public async getCurrentState(database: string, routeId: string): Promise<BusEta.PublicState> {
      return await this._http.post("/public/routes/getState", {
        routeId: routeId,
        database: database
      });
    }
}
