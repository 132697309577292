import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, DatePipe } from "@angular/common";
import { SelectServicePage } from "./pages/select-service/select-service.page";
import { LoginHeaderComponent } from "./shared/login/header/login-header.component";
import { LoginEnterPhonePage } from "./pages/login-enter-phone/login-enter-phone.page";
import { FormsModule } from "@angular/forms";
import { LoginVerifyCodePage } from "./pages/login-verify-code/login-verify-code.page";
import { LoginSuccessPage } from "./pages/login-success/login-success.page";
import { InitalizePage } from "./pages/initalize/initalize.page";
import { SelectBusServicePage } from "./pages/select-bus-service/select-bus-service.page";
import { SelectPublicServicePage } from "./pages/select-public-service/select-public-service.page";
import { NotifierService } from "./services/notify.service";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRippleModule } from "@angular/material/core";
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NotifyStopComponent } from "./pages/main/notify-dialogue/notifyStop.dialogue";
import { PublicRoutesService } from './services/public.routes.service';

@NgModule({
  declarations: [
    AppComponent,
    InitalizePage,
    LoginHeaderComponent,
    SelectServicePage,
    SelectPublicServicePage,
    SelectBusServicePage,
    LoginEnterPhonePage,
    LoginVerifyCodePage,
    LoginSuccessPage,
    NotifyStopComponent
  ],
  entryComponents: [],
    imports: [
        BrowserModule,
        CommonModule,
        IonicModule.forRoot({animated: false}),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatRippleModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatMenuModule
    ],
    providers: [
        DatePipe,
        PublicRoutesService,
        NotifierService, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
