import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BusEta } from 'src/app/app';

@Component({
    selector: 'app-active-route-panel',
    templateUrl: './active-route-panel.component.html',
    styleUrls: ['./active-route-panel.component.scss'],
})
export class ActiveRoutePanelComponent implements OnInit {

    @Input() state: BusEta.PublicState;

    @Input() route: BusEta.Route;

    @Input() deviceId: string;

    @Output() back = new EventEmitter();

    @Output() deviceEta = new EventEmitter<string>();

    constructor() { }

    ngOnInit() { }

    public selectRow(stop: BusEta.RouteStop) {

    }

    public selectDevice(deviceId: string) {
        this.deviceEta.emit(deviceId);
    }

    public getDevicesName() {
        return this.route.devices.map(d => d.name).join(", ");
    }

    public getDeviceName(deviceId: string) {
        const device = this.route.devices.find(d => d.id == deviceId);
        return device ? device.name : "";
    }

    public getStopTime(stopId: string) {
        if (this.deviceId) {
            return this.getDeviceEta(stopId)?.time || 0;
        }
        return this.getStopState(stopId)?.time || 0;
    }

    public isStopLate(stopId: string) {
        return this.getStopState(stopId)?.late || false;
    }

    public getStopState(stopId: string) {
        return this.state.stops.find(s => s.stopId === stopId);
    }

    public getDeviceEta(stopId: string): BusEta.PublicStop {
        if (!this.state.devicesEta) {
            return null;
        }
        const devEta = this.state.devicesEta.find(s => s.deviceId == this.deviceId);
        if (!devEta) {
            return null;
        }
        return devEta.stops.find(e => e.stopId == stopId);
    }

    public stopDeviceVisible(stopId: string) {

        const stop = this.state.stops.find(s => s.stopId === stopId);

        if (!stop || !stop.lastDeviceId) {
            return false;
        }

        return this.deviceId ? this.getDeviceEta(stopId).lastDeviceId == this.deviceId: true;
    }

    public stopDeviceMoving(stopId: string) {
        
        // device visible for stop check if its moving
        if (!this.deviceId) {
            const stop = this.state.stops.find(s => s.stopId === stopId);
            return !stop.atStopNow;
        }

        const deviceEta = this.state.devicesEta.find(d => d.deviceId == this.deviceId);
        return deviceEta && deviceEta.state == "moving";
    }

    public onBack() {
        this.back.emit(null);
    }
}
