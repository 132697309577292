<mat-dialog-content class="component-notify-stop">

    <div class="add"
         *ngIf="mode === 'add'">
        <div class="header-title">
            Select the time window when you want to be notified
        </div>
        <div *ngIf="!isLoading">
            <mat-label>Start Time:</mat-label>
            <mat-form-field floatLabel="never"
                            class="full-width">
                <img class="timer-icon"
                     matPrefix
                     src="assets/svg/time.svg">
                <input [(ngModel)]="startTime"
                       class="timerInput"
                       type="time"
                       matInput>
                <span matSuffix
                      (click)="setCurrentTime()"
                      class="currentTime">Current Time</span>
            </mat-form-field>
            <mat-label>End Time:</mat-label>
            <mat-form-field floatLabel="never"
                            class="full-width">
                <img class="timer-icon"
                     matPrefix
                     src="assets/svg/time.svg">
                <input [(ngModel)]="endTime"
                       class="timerInput endTime"
                       onblur
                       type="time"
                       matInput>
            </mat-form-field>
        </div>

        <div *ngIf="isLoading"
             class="loader">
            <mat-spinner [diameter]="60"></mat-spinner>
        </div>

        <div class="footer">
            <button mat-dialog-close
                    mat-button
                    class="cancel">Cancel</button>
            <button (click)="save()"
                    mat-button
                    class="save">Save</button>
        </div>

    </div>

    <div class="view"
         *ngIf="mode === 'view'">
        <div class="flex-center">
            <img src="assets/bell-view.png">
        </div>
        <div class="text">
            You will be notified about bus arrival for this route and stop between
        </div>
        <div class="text">
            <span>{{ convertToAMPM(notifyStopSettings.startTime.hr, notifyStopSettings.startTime.min )}}</span> and
            <span>{{ convertToAMPM(notifyStopSettings.endTime.hr, notifyStopSettings.endTime.min )}}</span>
        </div>
        <div class="flex-center">
            <button class="close"
                    mat-dialog-close="fetch"
                    mat-button>Close</button>
        </div>
    </div>

    <div class="remove"
         *ngIf="mode === 'remove'">
        <div class="flex-center">
            <img src="assets/bell-remove.png">
        </div>
        <div class="text">
            You will not be notified about bus arrival for this route and stop. </div>
        <div class="footer">
            <button class="cancel"
                    mat-dialog-close
                    mat-button>Cancel</button>
            <button class="confirm"
                    (click)="remove()"
                    mat-button>Confirm</button>
        </div>
    </div>

</mat-dialog-content>
