<app-login-header [title]="busType" [customerId]="customerId"></app-login-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col class="ion-no-padding">
        <h1>Welcome</h1>
        <h1 class="blue-title">Login to Continue</h1>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-end">
      <ion-col class="form-select" size="4">
        <select [(ngModel)]="countryCode" class="select-text" required>
          <option value="+1">+1</option>
          <option value="+2">+2</option>
          <option value="+3">+3</option>
          <option value="+4">+4</option>
          <option value="+5">+5</option>
          <option value="+6">+6</option>
          <option value="+7">+7</option>
          <option value="+8">+8</option>
          <option value="+9">+9</option>
        </select>
        <span class="select-highlight"></span>
        <span class="select-bar"></span>
        <label class="select-label">Country Code</label>
      </ion-col>
      <ion-col class="form-input">
        <ion-item>
          <ion-label class="placeholder" position="floating">647 658 7512</ion-label>
          <ion-input [(ngModel)]="phone"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="login ion-no-padding">
        <ion-button (click)="requestCode()" expand="full">Login</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
