import { Injectable } from '@angular/core';
import { BusEta } from '../app';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private _themeCache: { [key: string]: BusEta.Customization } = {};

  constructor(
    private _http: HttpService
  ) { }

  public async getList(): Promise<BusEta.Customer[]> {
    return await this._http.post("/customers/public/list");
  }

  /**
   * Get customization for specific customer
   */
  public async getTheme(customerId: string): Promise<BusEta.Customization> {
    if (this._themeCache[customerId]) {
      return this._themeCache[customerId];
    }
    const theme = await this._http.post("/customers/public/customization", { id: customerId });
    this._themeCache[customerId] = theme;
    return theme;
  }

  /**
   * Get customization for specific theme
   */
  public async getThemeByDb(database: string): Promise<BusEta.Customization> {
    if (this._themeCache[database]) {
      return this._themeCache[database];
    }
    const theme = await this._http.post("/customers/public/customization", { database: database });
    this._themeCache[database] = theme;
    return theme;
  }

}
