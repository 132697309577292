<app-login-header [title]="'Shuttle'"></app-login-header>
<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col>
                <div class="blue-title">Select the Location</div>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="dropdown">
                <div class="ion-select" (click)="toggleMenu()">Please Select the Location<ion-icon slot="end" class="drop-icon" [name]="ishidden ? 'chevron-down-outline': 'chevron-up-outline'"></ion-icon></div>
                <div class="dropdown-content">
                    <ion-searchbar (ionInput)="filterList($event)" placeholder="Search"></ion-searchbar>
                    <ion-progress-bar class="progress-bar" *ngIf="isLoading" type="indeterminate"></ion-progress-bar>
                    <div class="list-item" (click)="continue(service)" *ngFor="let service of services">{{service.name}}</div>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<div class="footer-logo">
  <img height="27" src="assets/zen.png"/>
</div>
