<div class="main">
    <ng-container *ngIf="!isLoading">

        <div class="show-md side-panel">
            <ng-container *ngIf="!route">
                <app-routes-list [database]="database"
                                 (routeSelected)="onRouteSelect($event)"></app-routes-list>
            </ng-container>
            <ng-container *ngIf="route">
                <app-active-route-panel [route]="route"
                                        [state]="state"
                                        [deviceId]="deviceId"
                                        (deviceEta)="selectDeviceEta($event)"
                                        (back)="onBack()"></app-active-route-panel>
            </ng-container>
        </div>

    </ng-container>


    <div class="map-wrapper">

        <div class="topbar">
            <div class="banners-wrapper">
                <app-banners *ngIf="userId"
                             [userId]="userId"></app-banners>
            </div>
            <div class="searchbar">
                <ion-searchbar class="hide-md ion-no-margin ion-no-padding"
                               showCancelButton="focus"
                               animated
                               (ionFocus)="toggleSearch($event)"
                               placeholder="Search Route">
                </ion-searchbar>
            </div>
        </div>

        <div class="loader"
             *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <div class="map"></div>
        <div *ngIf="notification"
             class="notification-wrapper hide-md">
            {{notification}}
        </div>

    </div>
</div>


<ng-bottom-sheet *ngIf="route || isSharedView"
                 class="hide-md"
                 [(state)]="sheetState"
                 (stateChange)="onBottomStateChange()"
                 [enableScrollContent]="false"
                 [minHeight]="bottomMinHeight"
                 [hideCloseButton]="true"
                 [titleCentered]="true"
                 [hideSeparator]="true"
                 [title]="''"
                 [hideTitle]="hideBottomTitle"
                 titleSize="15px"
                 [dockedHeight]="dockHeight"
                 [useViewport]="false"
                 [disableDrag]="true"
                 [disableDockState]="false">

    <!-- Stops for selected route -->
    <div *ngIf="route && state">

        <ion-grid class="stop">
            <ion-row class="ion-justify-content-between divider ion-align-items-center">
                <ion-col>
                    <div class="routeName">
                        <div #routeName class="title">{{ route.name }}</div>
                        <div class="timer">({{route.stops.length}} stops)</div>
                    </div>
                    <div class="lbl">
                        Buses on route:
                    </div>
                    <div #devicesList class="names">
                      <div class="name"
                           *ngIf="route.devices.length > 1"
                           [ngClass]="{'active': !deviceId}"
                           (click)="selectDeviceEta(null)">
                        All Buses</div>
                      <div class="name"
                           [ngClass]="{'active': deviceId == device.id || route.devices.length == 1}"
                           (click)="selectDeviceEta(device.id)"
                           *ngFor="let device of route.devices">
                        {{device.name}}
                      </div>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>

        <ion-grid class="stop scroll">
            <!-- show a simple list of stops for shared map -->
            <div *ngIf="isSharedView">
                <app-stops-list [route]="route"
                                [deviceId]="deviceId"
                                [state]="state"></app-stops-list>
            </div>


            <!-- public stops -->
            <ng-container *ngIf="!isSharedView">
                <ion-item-sliding (ionDrag)="handleSlide($event)"
                                  class="ion-no-padding ion-no-margin"
                                  [disabled]="isNotify(stop.id)"
                                  #sliders
                                  *ngFor="let stop of route.stops; let i = index">
                    <ion-item lines="none"
                              class="ion-no-padding">
                        <ion-row (click)="selectRow(i, stop.id)"
                                 *ngIf="i !== selectedIndex"
                                 class="full-width ion-justify-content-between ion-align-items-center">
                            <ion-col class="flex"
                                     size="8">
                                <div class="timeline-circle">
                                    <ion-label class="ion-no-padding ion-no-margin">{{i+1}}</ion-label>
                                    <div *ngIf="i < route.stops.length-1"
                                         class="timeline"></div>
                                </div>
                                <div class="stopName">
                                    <span class="stopBus"
                                          *ngIf="stopDeviceVisible(stop.id)">
                                        <img *ngIf="!stopDeviceMoving(stop.id)" src="assets/pin.svg">
                                        <img *ngIf="!stopDeviceMoving(stop.id)" src="assets/bus.svg">
                                        <img *ngIf="stopDeviceMoving(stop.id)" src="assets/moving.svg">
                                    </span>
                                    <ion-label>{{stop.name}}</ion-label>
                                </div>
                            </ion-col>
                            <ion-col size="3">
                                <div class="timer ion-float-end">
                                    <div *ngIf="isNotify(stop.id) && !isSharedView">
                                        <img (click)="removeDialogue(stop.id)"
                                             src="assets/bell.png">
                                    </div>
                                    <div *ngIf="getStopTime(stop.id)"
                                         [ngClass]="{'late': isStopLate(stop.id)}">
                                        {{ getStopTime(stop.id) |  date:'mediumTime' }}
                                    </div>
                                    <div *ngIf="!getStopTime(stop.id)">
                                        ...
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>
                        <ion-row *ngIf="i === selectedIndex"
                                 class="notifyBtn full-width">
                            <button (click)="addDialogue(stop.id)"
                                    class="full-width">
                                <ion-icon slot="start"
                                          src="assets/svg/bell.svg"></ion-icon>Notify Me
                            </button>
                        </ion-row>
                    </ion-item>
                    <ion-item-options side="end">
                        <ion-item-option (click)="addDialogue(stop.id)"
                                         class="notifyBtn">
                            <ion-icon slot="start"
                                      src="assets/svg/bell.svg"></ion-icon>
                            Notify Me
                        </ion-item-option>
                    </ion-item-options>
                </ion-item-sliding>
            </ng-container>
        </ion-grid>
    </div>

    <!-- route list -->
    <div *ngIf="!route && isSharedView && !isLoading">
        <app-routes-list-bottom-sheet [database]="database"></app-routes-list-bottom-sheet>
    </div>

</ng-bottom-sheet>
