import { Injectable } from '@angular/core';
import { BusEta } from '../app';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class NotifyStopService {

  constructor(
    private _http: HttpService
  ) { }

  public async find(userId: string, routeId: string): Promise<BusEta.NotifyStop[]> {
    return await this._http.post("/public/notify/stop/find", {
      userId: userId,
      routeId: routeId
    });
  }

  public async remove(id: string): Promise<BusEta.NotifyStop[]> {
    return await this._http.post("/public/notify/stop/remove", {
      _id: id
    });
  }

  public async save(notifyStopSettings: BusEta.NotifyStop) {
    return await this._http.post("/public/notify/stop/add", notifyStopSettings);
  }

}
