<ion-header>
    <!-- Customized -->
    <div class="header-bar"
         [ngStyle]="{'background-color': background }">
        <img [src]="logo"
             alt="img" />
        <div class="app-name">
            {{appName}}
        </div>
    </div>

</ion-header>

<ion-content>
    <ion-router-outlet></ion-router-outlet>
</ion-content>
