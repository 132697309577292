import { Component, ElementRef, OnChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { EventManager } from "@angular/platform-browser";
import { PublicRoutesService } from "../../../../../services/public.routes.service";
import { BusEta } from "../../../../../app";
import { AuthService } from "../../../../../services/auth.service";
import { NotifierService } from "../../../../../services/notify.service";
import { IonSearchbar } from "@ionic/angular";

@Component({
    selector: 'app-search-routes',
    templateUrl: './search-routes.component.html',
    styleUrls: ['./search-routes.component.scss'],
})

export class PublicRoutesComponent implements OnChanges {
    @ViewChild('searchBar') searchBar: IonSearchbar;

    public routes: BusEta.Route[];

    public routesFilter: BusEta.Route[];

    public isLoading = false;

    public database: string; sw;

    private unregisterClick?: Function;

    private unregisterTouch?: Function;

    private _isSharedView = false;

    constructor(
        private _notify: NotifierService,
        private _router: Router,
        private elementRef: ElementRef,
        private eventManager: EventManager,
        private _routeService: PublicRoutesService,
        private _auth: AuthService,
        private _activeRoute: ActivatedRoute
    ) {
        this._isSharedView = document.location.href.includes(`/shared/`);

        this._activeRoute.params.subscribe(params => {
            this.database = params.database;
        });

        this._router.events.subscribe(async event => {
            if (event instanceof NavigationEnd && event.url.startsWith(`/main/public/routes`)) {
                await this.initListRoutes();
            }
        });

    }

    ngAfterViewChecked() {
        if (undefined === this.unregisterClick && undefined === this.unregisterTouch) {
            const searchIcon: HTMLElement | null = this.elementRef.nativeElement.querySelector('.searchbar-search-icon');
            if (searchIcon) {
                searchIcon.style.pointerEvents = 'all';
                this.unregisterClick = this.eventManager.addEventListener(searchIcon, 'click', this.backClick.bind(this));
                this.unregisterTouch = this.eventManager.addEventListener(searchIcon, 'touch', this.backClick.bind(this));
            }
        }
    }

    public async ngOnInit() {
        this.isLoading = true;
        await this.initListRoutes();
    }

    private async initListRoutes() {
        const routes = await this._routeService.find(this.database);
        this.routes = [];
        for (const route of routes) {
            if (!route.devices || route.devices.length === 0) {
                continue;
            }
            this.routes.push(route);
        }
        this.routesFilter = this.routes.slice(0);
        this.isLoading = false;
    }

    public selectRoute(routeId: string) {
        
        // reset searchbar before navigate
        this.searchBar.value = "";
        
        if (this._isSharedView) {
            this._router.navigate([
                `/main/shared/map/${this.database}`,
                {
                    routeId: routeId
                }
            ]);
        }
        else {
            this._router.navigate([
                `/main/public/map/${this.database}`,
                {
                    routeId: routeId
                }
            ]);
        }
    }

    public search(event: Event) {
        const query = (<HTMLInputElement>event.target).value.toLowerCase();

        if (!query || !query.trim()) {
            // revert back to the original array if no query
            this.routesFilter = this.routes.slice(0);
            return;
        }

        this.routesFilter = this.routes.filter(r =>
            (r.name && r.name.toLowerCase().includes(query.toLowerCase()))
        );
    }

    ngOnChanges() { }

    public backClick() {
        
        // reset searchbar before navigate
        this.searchBar.value = "";
        
        if (this._isSharedView) {
            this._router.navigate([`/main/shared/map/${this.database}`]);
        }
        else {
            this._router.navigate([`/main/public/map/${this.database}`]);
        }
    }

}
