import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { BusEta } from "../../../app";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { NotifierService } from "../../../services/notify.service";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

    public settings: BusEta.UserSettings;

    public isLoading = false;

    constructor(
        private _settingsService: SettingsService,
        private _authService: AuthService,
        private _notifierService: NotifierService,
        private _router: Router
    ) { }

    ngOnInit() {
        this.isLoading = true;
        this.getSettings();
    }

    public async getSettings() {
        try {
            this.settings = await this._settingsService.get();
        } catch (err) {
            this._notifierService.error(err);
        } finally {
            this.isLoading = false;
        }
    }

    public async updateInput() {
        try {
            await this._settingsService.save(this.settings);
        } catch (err) {
            this._notifierService.error(err);
        }
    }

    public async logOut() {
        try {
            await this._authService.logout();
            this._router.navigate(["/select-service"]);
        } catch (err) {
            this._notifierService.error(err);
        }
    }

}
