import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { NotifierService } from "../../services/notify.service";

@Component({
  selector: 'app-select-service',
  templateUrl: './select-service.page.html',
  styleUrls: ['./select-service.page.scss']
})
export class SelectServicePage {

  constructor(
    private _customerService: CustomerService,
    private _router: Router,
    private _notify: NotifierService
  ) { }

  public async continue(busType: string) {
    try {
      if(busType === 'shuttle') {
        this._router.navigate(["/select-public-service"]);
        return;
      }
      this._router.navigate(["/login-enter-phone"], { queryParams: { busType: 'School Bus'} });
    }
    catch (err) {
      this._notify.error(err);
    }
  }

}
