import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { NotifierService } from "../../services/notify.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: 'app-main',
    templateUrl: './main.page.html',
    styleUrls: ['./main.page.scss'],
})
export class MainPage implements OnInit {

    public logo = "assets/logo.png";

    public background = "";

    public guest = false;

    public multiLogin = false;

    constructor(
        private _auth: AuthService,
        private _notify: NotifierService,
        private _customerService: CustomerService,
        private _activeRoute: ActivatedRoute,
        private _dialog: MatDialog
    ) {
        this.guest = this._activeRoute.snapshot.url.length > 0;
        this.multiLogin = this.guest || this._auth.dbsAuthData && this._auth.dbsAuthData.length > 1;
    }

    ngOnInit() {
        // guest login
        if (this.guest) {
            return;
        }

        this.init();
    }

    private async init() {
        try {
            const database = this._auth.authData.database;
            const theme = await this._customerService.getThemeByDb(database);
            if (theme && theme.headerLogo) {
                this.logo = theme.headerLogo;
                this.background = theme.portalHeaderColor;
            }
        }
        catch (err) {
            this._notify.error(err);
        }
    }

}
