import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-initalize',
  templateUrl: './initalize.page.html',
  styleUrls: ['./initalize.page.scss'],
})
export class InitalizePage implements OnInit {

  constructor(
    private _auth: AuthService,
    private _firebase: FirebaseService,
    private router: Router
  ) { }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      // initalize auth
      await this._auth.initalize();

      console.log(this._auth.token);
      if (this._auth.token) {
        try {
          await this._auth.verify();
          this.finalize();
        }
        catch (err) {
          console.error(err);
          this.redirectToServiceSelect();
        }
      }
      else {
        this.redirectToServiceSelect();
      }

    }
    catch (err) {
      console.error(err);
    }
  }

  private redirectToServiceSelect() {
    this.router.navigate(['/select-service'], { replaceUrl: true });
  }

  private async finalize() {

    try {
      // initalize firebase notifications
      await this._firebase.init();
    }
    catch (err) {
      console.error(err);
    }

    this.router.navigate(['/main'], { replaceUrl: true });
  }

}
