import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class NotifierService {
    constructor(
        private _toast: ToastController) {
    }

    public async info(msg: string, offset?: boolean) {
        const offsetClass = offset ? "toast-inner-offset" : "";
        const toast = await this._toast.create({
            message: msg,
            duration: 5000,
            cssClass: offsetClass,
            position: "bottom"
        });
        toast.present();
    }

    public async error(errText: Object, err?: Object) {
        console.error(errText, err);

        let msg = `${this.getErrorMessage(errText)}`;

        if (err) {
            msg += ` Reason: ${this.getErrorMessage(err)}`
        }

        if (msg && msg.length > 500) {
            msg = msg.substring(0, 500);
        }

        const toast = await this._toast.create({
            message: msg.trim(),
            color: "danger",
            duration: 5000,
            position: "bottom",
            cssClass: "app-toast"
        });
        toast.present();
    }

    public getErrorMessage(err) {
        if (!err) {
            return "";
        }
        if (typeof err == "string") {
            return err;
        }
        else if (typeof err.error == "string") {
            return err.error;
        }
        else if (err.message) {
            return err.message;
        }
        else if (err.status && err.statusText) {
            return `Http error ${err.status} ${err.statusText}`;
        }
        else if (err.statusText) {
            return err.statusText;
        }
        else {
            console.warn("Can't extract error message from object:", err);
            return "";
        }

    }
}
