import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BusEta } from 'src/app/app';
import { NotifierService } from 'src/app/services/notify.service';
import { PublicRoutesService } from 'src/app/services/public.routes.service';

@Component({
    selector: 'app-routes-list-bottom-sheet',
    templateUrl: './routes-list-bottom-sheet.component.html',
    styleUrls: ['./routes-list-bottom-sheet.component.scss'],
})
export class RoutesListBottomSheetComponent implements OnChanges {

    @Input() database = "";

    public routes: BusEta.Route[] = [];

    public isLoading = false;

    constructor(
        private _router: Router,
        private _notify: NotifierService,
        private _routeService: PublicRoutesService
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.database) {
            this.loadRoutes();
        }
    }

    private async loadRoutes() {
        try {
            this.isLoading = true;
            const loadedRoutes = await this._routeService.find(this.database);
            this.routes = loadedRoutes.filter(r => r.name.trim() !== "");
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public selectRoute(route: BusEta.Route) {
        this._router.navigate([
            `/main/shared/map/${route.database}`,
            {
                routeId: route._id
            }
        ]);
    }
}
