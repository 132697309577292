import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'src/app/services/notify.service';
import { PublicRoutesService } from 'src/app/services/public.routes.service';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _notify: NotifierService
    ) { }

    ngOnInit() {
        this.redirect();
    }

    private async redirect() {
        try {
            const database = this._activatedRoute.snapshot.params.database;
            this._router.navigate([`/main/shared/map/${database}`]);
        }
        catch (err) {
            this._notify.error(err);
        }
    }

}
