<div *ngIf="route && state"
     class="component">
    <ion-item *ngFor="let stop of route.stops; let i = index"
              lines="none"
              class="ion-no-padding">
        <ion-row class="full-width ion-justify-content-between ion-align-items-center">
            <ion-col class="flex"
                     size="8">
                <div class="timeline-circle">
                    <ion-label class="ion-no-padding ion-no-margin">{{i+1}}</ion-label>
                    <div *ngIf="i < route.stops.length-1"
                         class="timeline"></div>
                </div>
                <div class="stopName">
                    <span class="stopBus"
                          *ngIf="stopDeviceVisible(stop.id)">
                        <img *ngIf="!stopDeviceMoving(stop.id)" src="assets/pin.svg">
                        <img *ngIf="!stopDeviceMoving(stop.id)" src="assets/bus.svg">
                        <img *ngIf="stopDeviceMoving(stop.id)" src="assets/moving.svg">
                    </span>
                    <ion-label>{{stop.name}}</ion-label>
                </div>
            </ion-col>
            <ion-col size="3">
                <div class="timer ion-float-end">
                    <div *ngIf="getStopTime(stop.id)"
                         [ngClass]="{'late': isStopLate(stop.id)}">
                        {{ getStopTime(stop.id) |  date:'mediumTime' }}
                    </div>
                    <div *ngIf="!getStopTime(stop.id)">
                        ...
                    </div>
                </div>
            </ion-col>
        </ion-row>
    </ion-item>
</div>
