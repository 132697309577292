import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { NotifierService } from 'src/app/services/notify.service';
import { PublicRoutesService } from 'src/app/services/public.routes.service';
import { UIService } from 'src/app/services/ui.service';

@Component({
    selector: 'app-shared-page',
    templateUrl: './shared-page.component.html',
    styleUrls: ['./shared-page.component.scss'],
})
export class SharedPageComponent implements OnInit {
    public logo = "assets/logo.png";

    public background = "";

    public appName = "";

    constructor(
        private _customerService: CustomerService,
        private _activatedRoute: ActivatedRoute,
        private _notify: NotifierService,
        private _routesService: PublicRoutesService,
        private _router: Router,
        private _uiService: UIService
    ) {
    }

    ngOnInit() {
        this.init();
    }

    private async init() {
        try {
            let database = this._activatedRoute.snapshot.params.database;
            if (!database) {
                // try pick database name from first child
                if (this._activatedRoute.snapshot.firstChild) {
                    database = this._activatedRoute.snapshot.firstChild.params.database;
                }
            }
            if (!database) {
                return;
            }
            const theme = await this._customerService.getThemeByDb(database);
            if (theme) {
                if (theme.headerLogo) {
                    this.logo = theme.headerLogo;
                    this.background = theme.portalHeaderColor;
                }

                this.appName = theme.appName;
            }

            // load routes
            const routes = await this._routesService.find(database);
            const isSmallSize = this._uiService.isSmallScreen();
            if (routes.length == 1) {
                const route = routes[0];

                //  open to the first route
                this._router.navigate([`/main/shared/map/${database}`, { routeId: route._id }]);
            }
            else {
                if (isSmallSize) {
                    // redirect to routes
                    this._router.navigate(["/main/shared/routes", { database: database }]);
                }
            }
        }
        catch (err) {
            this._notify.error(err);
        }
    }

}
