<app-login-header [title]="''"></app-login-header>
<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col>
                <h1>Welcome</h1>
                <h1 class="blue-title">Select Your Service to Continue</h1>
            </ion-col>
        </ion-row>
        <ion-row>
          <ion-col (click)="continue('shuttle')" class="thumbnail">
            <ion-thumbnail>
              <img height="82" width="87" alt="Silhouette of mountains" src="assets/shuttle.svg" />
              <div class="bus-type">
                Shuttle
              </div>
            </ion-thumbnail>
          </ion-col>
          <ion-col (click)="continue('bus')" class="thumbnail" offset="0.5">
            <ion-thumbnail>
              <img alt="Silhouette of mountains" src="assets/bus_m.svg" />
              <div class="bus-type">
                School Bus
              </div>
            </ion-thumbnail>
          </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<div class="footer-logo">
  <img height="27" src="assets/zen.png"/>
</div>
