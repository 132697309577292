import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { BusEta } from 'src/app/app';
import { AuthService } from 'src/app/services/auth.service';
import { MapService } from 'src/app/services/map.service';
import { ActivatedRoute, Router } from "@angular/router";
import { SheetState } from "ng-bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Platform } from "@ionic/angular";
import { EventManager } from "@angular/platform-browser";
import { NotifierService } from 'src/app/services/notify.service';
import { NotifyStopService } from 'src/app/services/notify.stop.service';
import { PublicFirebaseService } from 'src/app/services/public.firebase.service';
import { PublicRoutesService } from 'src/app/services/public.routes.service';
import { NotifyStopComponent } from '../../../notify-dialogue/notifyStop.dialogue';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})

export class PublicMapComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('devicesList', { static: false }) devicesList: ElementRef;

    @ViewChild('routeName', { static: false }) routeName: ElementRef;

    @ViewChildren('sliders') sliders: QueryList<HTMLIonItemSlidingElement>;

    public deviceId: string;

    public slider: HTMLIonItemSlidingElement;

    /**
     * notification text
     */
    public notification = "";

    /**
     * State for current component
     */
    private _isDestroyed = false;

    public route: BusEta.Route;

    public routes: BusEta.Route[];

    public routeId: string;

    public userId: string;

    public database: string;

    public isLoading = false;

    public state: BusEta.PublicState;

    public isAnimated = false;

    /**
     * hide/show bottom title
     */
    public hideBottomTitle = true;

    /**
     * Min height in px for bottom sheet
     */
    public bottomMinHeight = 94;

    public dockHeight: number;

    public sheetState = SheetState.Bottom;

    public currentTime: Date = new Date();

    public notifyStops: BusEta.NotifyStop[] = [];

    public selectedIndex: number;

    public isSharedView = false;

    private _map: H.Map;

    private _cancellation: { cancelled: boolean } = null;

    private _objects: {
        points?: H.map.DomMarker[];
        buses?: H.map.DomMarker[];
        stop?: H.map.DomMarker;
        end?: H.map.DomMarker;
        route?: {
            completed?: H.map.Polyline;
            incompleted?: H.map.Polyline;
        };
    } = { buses: [], points: [], route: {} };

    constructor(
        private _elementRef: ElementRef,
        private _mapService: MapService,
        private _auth: AuthService,
        private _routeService: PublicRoutesService,
        private _notifyStopService: NotifyStopService,
        private _notify: NotifierService,
        private _router: Router,
        private _activeRoute: ActivatedRoute,
        private _changeRef: ChangeDetectorRef,
        private _firebaseService: PublicFirebaseService,
        private _dialog: MatDialog,
        private platform: Platform,
        private eventManager: EventManager
    ) {
        // subscribe to route
        this._activeRoute.params.subscribe(params => {
            // check for params
            if (Object.keys(params).length > 0) {
                this.routeId = params.routeId;
                this.database = params.database;
            }
            delete this.selectedIndex;

            this.updateBottomSheet();
        });

        // detect shared view by url
        this.isSharedView = document.location.href.includes(`/shared/`);
    }

    private updateBottomSheet() {
        if (this.isSharedView) {
            if (!this.routeId) {
                this.bottomMinHeight = 60;
                this.dockHeight = window.innerHeight * 60 / 100;
            }
        }
    }

    private calculateBottomSheetHeight() {

        // maximum 48% of screen
        let maxDockHeight = window.innerHeight * 48 / 100;

        // route tile height
        const routeTitleHeight = this.routeName.nativeElement.getBoundingClientRect().height;
        this.bottomMinHeight += routeTitleHeight;

        if (this.route && this.route.stops.length < 6) {
            const minDockHeight = this.bottomMinHeight + 48 * this.route.stops.length;
            if (minDockHeight < maxDockHeight) {
                maxDockHeight = minDockHeight;
            }
        }

        // devicesList height
        const devicesListHeight= this.devicesList.nativeElement.getBoundingClientRect().height;
        this.dockHeight = maxDockHeight + devicesListHeight;

    }

    ngOnInit() {

        // check available height for bottomsheet
        this.platform.ready().then(() => {
            window.addEventListener('resize', this.onWindowResize);
        });

        this.initFirebase();
        this.init();
    }

    ngAfterViewInit() {
      this.routeTitleCheck();
    }

    ngOnDestroy() {
        this._isDestroyed = true;
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize = () => {
        this.updateBottomSheet();
    };

    private async init() {
        try {

            // initalize feed
            await this.initFeed();

            const container = this._elementRef.nativeElement.getElementsByClassName("map").item(0) as HTMLElement;
            this._map = this._mapService.createMap(container);

            this._mapService.resizeMap(this._map, container);

            // subscribe to firebase notifications
            this._firebaseService.getEvents().subscribe((options) => {
                this.showNotify(options as BusEta.FirebaseEvent);
            });

            const dragIcon: HTMLElement | null = this._elementRef.nativeElement.querySelector('#drag-icon');
            if (dragIcon) {
                this.eventManager.addEventListener(dragIcon, 'touch', this.toggleSheet.bind(this));
                this.eventManager.addEventListener(dragIcon, 'click', this.toggleSheet.bind(this));
            }
        }
        catch (err) {
            this._notify.error(`Initalization failed`, err);
        }
    }

    public async initFirebase() {
        this.userId = await this._auth.loginWithUserId();
        await this._firebaseService.init(this.userId);
    }

    private async initFeed() {

        this.isLoading = true;
        try {

            // cancel previous feed
            this.cancelFeed();

            if (!this.routeId) {
                return;
            }

            // fetch travellers and routes
            this.routes = await this._routeService.find(this.database);
            this.route = this.routes.find(r => r._id === this.routeId);
            this.notifyStops = await this._notifyStopService.find(this._firebaseService.userId, this.routeId);

            // create new cancellation object
            this._cancellation = { cancelled: false };

            // Run Feed
            this.runFeed(this.routeId, this._cancellation);
        }
        catch (err) {
            console.error(err);
            this._notify.error(`Initalization failed`, err);
        }
        finally {
            this.isLoading = false;
        }
    }

    routeTitleCheck() {
        const interval = setInterval(() => {
            if (this.routeName && this.devicesList) {
                this.calculateBottomSheetHeight();
                clearInterval(interval);
            }
        }, 1000);
    }

    private showNotify(options: BusEta.FirebaseEvent) {
        // put some logig to handel text
        this.notification = options.text;
        // manually trigger change detection
        this._changeRef.detectChanges();
    }

    public selectRow(selectedIndex: number, stopId: string) {
        if (this.isNotify(stopId)) {
            return;
        }
        this.selectedIndex = selectedIndex;
    }

    /**
     * run infinity feed for status update
     */
    private async runFeed(routeId: string, cancellation: { cancelled: boolean }) {

        let isFirstRun = true;

        while (true) {
            try {
                if (cancellation && cancellation.cancelled) {
                    // feed cancelled
                    return;
                }

                if (this._isDestroyed) {
                    // component was destroyed
                    return;
                }

                this.state = await this._routeService.getCurrentState(this.database, routeId);
                if (!this.state) {
                    // continue when the state is not available
                    await this.sleep(5000);
                    continue;
                }

                if (!this.route) {
                    // stops are not available, so don't create a path
                    this.createBusMarker();
                    this._mapService.zoomTo(this._map, this.state.buses.map(b => b.location));
                    continue;
                }

                // update map
                this.updateMap();

                // zoom for first run
                if (isFirstRun) {
                    this.zoom();
                    isFirstRun = false;
                }
            }
            catch (err) {
                console.error(`Feed failed:`, err);
            }
            finally {
                // sleep 10 sec before next update
                await this.sleep(10000);
            }
        }
    }

    private sleep(timeout: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, timeout);
        });
    }

    /**
     * Update map
     */
    private updateMap() {

        this.createBusMarker();

        this.createStopsPoint();

        this.createRoute();
    }

    private zoom() {
        // zoom to route
        this._mapService.zoomTo(this._map, this.route.path.concat(this.state.buses.map(b => b.location)));
    }

    private createRoute() {
        const completePath = new H.geo.LineString();
        for (const p of this.route.path) {
            completePath.pushLatLngAlt(p.lat, p.lng, 0);
        }
        this._objects.route.completed = new H.map.Polyline(completePath, {
            style: {
                fillColor: "#2188D9",
                strokeColor: "#2188D9",
                lineWidth: 5
            }
        });
        this._map.addObject(this._objects.route.completed);

    }

    private createStopsPoint() {

        // create points for all stops
        let stopIdx = 0;
        for (const stop of this.route.stops) {
            const pos = new H.geo.Point(stop.location.lat, stop.location.lng);
            const domIcon = new H.map.DomIcon(
                `<div class="stop-point">` +
                `<ion-label class="ion-no-padding ion-no-margin">${stopIdx + 1}</ion-label>` +
                `</div>`
            );
            // check if stop point exists
            if (this._objects.points[stopIdx]) {
                this._objects.points[stopIdx].setIcon(domIcon);
            }
            else {
                this._objects.points[stopIdx] = new H.map.DomMarker(pos, {
                    icon: domIcon
                });
                this._map.addObject(this._objects.points[stopIdx]);
            }
            stopIdx++;
        }
    }

    private createBusMarker() {

        let i = 0;
        for (const bus of this.state.buses) {

            if (!bus.location) {
                continue;
            }

            const pos = new H.geo.Point(bus.location.lat, bus.location.lng);
            const marker = new H.map.DomMarker(pos, {
                icon: new H.map.DomIcon(
                  `<div class="bus-wrapper"><div class="bus-tooltip">${bus.deviceName}</div><div class="bus-marker"></div></div>`
                ),
                data: {
                    id: bus.deviceId
                }
            });

            marker.addEventListener("tap", () => {
                this.selectDeviceEta(bus.deviceId);
            });

            if (this._objects.buses[i]) {
                this._objects.buses[i].setGeometry(pos);
            }
            else {
                this._map.addObject(marker);
                this._objects.buses.push(marker);
            }
            this._objects.buses[i].setVisibility(bus.visible || false);
            i++;
        }

    }

    public selectDeviceEta(deviceId: string) {
        this.deviceId = deviceId;
        this.highlightBus();
        delete this.selectedIndex;
        if (this.deviceId) {
          return;
        }
        this.zoom();
    }

    public highlightBus() {

        for (const [index, element] of Object.entries(this._objects.buses)) {

            const bus = this.state.buses.find(b => b.deviceId == element.getData().id);

            // highlight all buses
            if (!this.deviceId) {
                const highlightIcon = new H.map.DomIcon(
                  `<div class="bus-wrapper"><div class="bus-tooltip">${bus.deviceName}</div><div class="bus-marker"></div></div>`
                );
                this._objects.buses[index].setIcon(highlightIcon);
                continue;
            }

            // highlight selected bus
            if (element.getData().id == this.deviceId) {
                const highlightIcon = new H.map.DomIcon(
                  `<div class="bus-wrapper"><div class="bus-tooltip">${bus.deviceName}</div><div class="bus-marker"></div></div>`
                );
                this._objects.buses[index].setIcon(highlightIcon);
                this._mapService.zoomTo(this._map, [bus.location]);
                continue;
            }

            // blur bus
            const blurIcon = new H.map.DomIcon(`<div class="bus-wrapper"><div class="blur-bus-marker"></div></div>`);
            this._objects.buses[index].setIcon(blurIcon);
        }
    }

    public toggleSearch(e: Event) {
        e.preventDefault();
        this._isDestroyed = true;
        if (this.isSharedView) {
            this._router.navigate(["/main/shared/routes", { database: this.database }]);
        }
        else {
            this._router.navigate(["/main/public/routes", { database: this.database }]);
        }
    }

    // getStopArrivalTime
    public getStopArrivalTime(index: number) {
        let arrivalTime = this.currentTime.getTime();
        for (let i = 1; i <= index; i++) {
            const stop = this.route.stops[i];
            arrivalTime = arrivalTime + stop.driveTime + stop.stopTime;
        }
        return arrivalTime;
    }

    public onBottomStateChange() {
        if (this.isAnimated) {
            return;
        }
        this.animate();
        this.isAnimated = true;
    }

    public toggleSheet() {
        this.sheetState = this.sheetState === SheetState.Bottom ? SheetState.Docked : SheetState.Bottom;
        delete this.selectedIndex;
        this.updateBottomSheet();
        this.onBottomStateChange();
    }

    public addDialogue(stopId: string) {
        const dialogRef = this._dialog.open(NotifyStopComponent, {
            width: '319px',
            data: {
                routeId: this.routeId,
                stopId: stopId,
                mode: 'add'
            },
            hasBackdrop: true
        });

        dialogRef.componentInstance.dataSent.subscribe(async () => {
            this.notifyStops = await this._notifyStopService.find(this._firebaseService.userId, this.routeId);
            delete this.selectedIndex;
            this.closeAllSliders();
        });

    }

    public removeDialogue(stopId: string) {

        const notifyStop = this.notifyStops.find(n => n.stopId === stopId);
        const dialogRef = this._dialog.open(NotifyStopComponent, {
            width: '319px',
            data: {
                id: notifyStop._id,
                mode: 'remove'
            },
            hasBackdrop: true
        });

        dialogRef.afterClosed().subscribe(async (action) => {
            if (action === "confirm") {
                this.notifyStops = this.notifyStops.filter(n => n._id !== notifyStop._id);
            }
        });

    }

    public isNotify(id: string) {
        const index = this.notifyStops.findIndex(n => n.stopId === id);
        return index > -1;
    }

    public getDevicesName() {
        return this.route.devices.map(d => d.name).join(", ");
    }

    public getDeviceName(deviceId: string) {
        const device = this.route.devices.find(d => d.id == deviceId);
        if (!device) {
            return;
        }
        return device.name;
    }

    public getStopLastDeviceId(stopId: string) {
        return this.state.stops.find(s => s.stopId === stopId)?.lastDeviceId;
    }

    public stopDeviceVisible(stopId: string) {

        const stop = this.state.stops.find(s => s.stopId === stopId);

        if (!stop || !stop.lastDeviceId) {
            return false;
        }

        return this.deviceId ? this.getDeviceEta(stopId).lastDeviceId == this.deviceId: true;
    }

    public stopDeviceMoving(stopId: string) {

        // device visible for stop check if its moving
        if (!this.deviceId) {
            const stop = this.state.stops.find(s => s.stopId === stopId);
            return !stop.atStopNow;
        }

        const deviceEta = this.state.devicesEta.find(d => d.deviceId == this.deviceId);
        return deviceEta && deviceEta.state == "moving";
    }

    public getStopTime(stopId: string) {
        if (this.deviceId) {
            return this.getDeviceEta(stopId)?.time || 0;
        }
        return this.state.stops.find(s => s.stopId === stopId)?.time;
    }

    public getDeviceEta(stopId: string) {
        if (!this.state.devicesEta) {
            return null;
        }
        const devEta = this.state.devicesEta.find(s => s.deviceId == this.deviceId);
        if (!devEta) {
            return null;
        }
        return devEta.stops.find(e => e.stopId == stopId);
    }

    public isStopLate(stopId: string) {
        return this.state.stops.find(s => s.stopId === stopId)?.late;
    }

    public async animate() {
        const itemSlider = this.sliders.toArray()[0];
        if (!itemSlider) {
            return;
        }

        itemSlider.open('end');
        // close in 1s
        setTimeout(() => { itemSlider.close(); }, 1000);
    }

    public async closeAllSliders() {
        const itemSlider = this.sliders.toArray()[0];
        if (!itemSlider) {
            return;
        }
        itemSlider.closeOpened();
    }

    public handleSlide(event: any): void {
        if (event.detail.amount > 50) {
            delete this.selectedIndex;
        }
    }

    /**
     * Handle route selection
     */
    public onRouteSelect(route: BusEta.Route) {
        this.routeId = route._id || "";
        this.route = route;

        // cancel previous feed
        this.cancelFeed();

        // remove previous map objects
        this.clearMap();

        // Run new feed
        this._cancellation = { cancelled: false };
        this.runFeed(this.routeId, this._cancellation);
    }

    private clearMap() {
        if (this._objects.buses) {
            this._map.removeObjects(this._objects.buses);
            this._objects.buses = [];
        }
        if (this._objects.points) {
            this._map.removeObjects(this._objects.points);
        }
        if (this._objects.route) {
            if (this._objects.route.completed) {
                this._map.removeObject(this._objects.route.completed);
            }
            if (this._objects.route.incompleted) {
                this._map.removeObject(this._objects.route.incompleted);
            }
        }
        if (this._objects.stop) {
            this._map.removeObject(this._objects.stop);
        }
        if (this._objects.end) {
            this._map.removeObject(this._objects.end);
        }

        this._objects = { buses: [], points: [], route: {} };
    }

    private cancelFeed() {
        if (this._cancellation) {
            this._cancellation.cancelled = true;
            this._cancellation = null;
        }
    }

    /**
     * handle back button
     */
    public onBack() {
        this.route = null;
        this.routeId = "";
        this.cancelFeed();
    }
}
