import { AfterViewChecked, Component, ElementRef, OnChanges, OnInit } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { EventManager } from "@angular/platform-browser";
import { RoutesService } from "../../../../services/routes.service";
import { BusEta } from "../../../../app";
import { TravellerService } from "../../../../services/traveller.service";

@Component({
  selector: 'app-search-routes',
  templateUrl: './search-routes.component.html',
  styleUrls: ['./search-routes.component.scss'],
})

export class SearchRoutesComponent implements OnInit, AfterViewChecked {

    public travellers: BusEta.Traveller[];

    public travellersFilter: BusEta.Traveller[];

    public isLoading = false;

    constructor(
        private _router: Router,
        private elementRef: ElementRef,
        private eventManager: EventManager,
        private _routeService: RoutesService,
        private _travellerService: TravellerService
    ) { }

    public async ngOnInit() {
        this._router.events.subscribe(async event => {
            if (event instanceof NavigationEnd && event.url === `/main/search-routes`) {
                await this.initListRoutes();
            }
        });
    }

    public async initListRoutes() {
        this.isLoading = true;
        this.travellers = await this._travellerService.find();
        this.travellersFilter = [...this.travellers];
        this.isLoading = false;
    }

    public selectRoute(routeId: string, travellerId: string) {
        this._router.navigate([
            "/main/map/bus",
            {
                routeId: routeId,
                travellerId: travellerId
            }
        ]);
    }

    public search(event: Event) {
        const query = (event.target as HTMLInputElement).value.toLowerCase();
        if (!query) {
            // revert back to the original array if no query
            this.travellersFilter = [...this.travellers];
        } else {
          this.travellersFilter = this.travellers.map(traveller => {
            const filteredItems = traveller.readonly.routes.filter(r => r.routeName.toLowerCase().includes(query));
            return { ...traveller, readonly: { routes: filteredItems } };
          });
        }
    }

    ngAfterViewChecked() {
      const searchIcon: HTMLElement | null = this.elementRef.nativeElement.querySelector('.searchbar-search-icon');
      if (searchIcon) {
        searchIcon.style.pointerEvents = 'all';
        this.eventManager.addEventListener(searchIcon, 'click', () => {
          this._router.navigate(["/main/map/bus"]);
        });
      }
  }
}
