<div class="component">

    <div class="lbl">
        Routes
    </div>
    <div class="list">
        <ion-progress-bar *ngIf="isLoading" type="indeterminate"></ion-progress-bar>
        <div *ngFor="let route of routes;"
             class="route-item"
             (click)="selectRoute(route)">
            <ion-icon src="assets/svg/route.svg"></ion-icon>
            <div class="route-name">{{ route.name | titlecase }}</div>
        </div>
    </div>
</div>
