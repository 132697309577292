<ion-content>
    <ion-refresher slot="fixed"
                   (ionRefresh)="doRefresh($event)">
        <ion-refresher-content pullingIcon="arrow-dropdown"
                               pullingText="Pull to refresh"
                               refreshingText="Refreshing...">
        </ion-refresher-content>
    </ion-refresher>

    <div class="header">
        <div class="block">
            <div class="text">Notifications</div>
            <div class="dot"
                 *ngIf="newNotifications"></div>
            <div class="new-notifications"
                 *ngIf="newNotifications">
                {{newNotifications}} New
            </div>
        </div>
        <div *ngIf="!guest" class="block">
            <div class="filter-btn"
                 [matMenuTriggerFor]="menu">
                <ion-icon name="filter-outline"></ion-icon>
            </div>
            <mat-menu #menu="matMenu"
                      class="filter-menu">
                <button mat-menu-item
                        [class.selected]="filterType == 'all'"
                        (click)="setFilter('all')">
                    All Notifications
                </button>
                <button mat-menu-item
                        [class.selected]="filterType == 'tag'"
                        (click)="setFilter('tag')">
                    Tag Notifications
                </button>
                <button mat-menu-item
                        [class.selected]="filterType == 'bus'"
                        (click)="setFilter('bus')">
                    Bus Activity Notificiations
                </button>
            </mat-menu>
        </div>
    </div>

    <div *ngIf="!isLoading">

        <div *ngFor="let notification of notifications"
             class="notification"
             [class.new]="!notification.viewed">
            <div class="text">
                {{notification.text}}
            </div>
            <div class="type">
                <span>
                    {{getNotificationTypeLabel(notification)}}
                </span>
                <span class="dot">
                </span>
                <div>
                    {{getTime(notification)}}
                </div>
            </div>
        </div>

        <div *ngIf="notifications.length == 0"
             class="no-data">
            You don’t have any notifications yet
        </div>
    </div>

    <div *ngIf="isLoading"
         class="loading-wrapper">
        <div class="text">
            Loading...
        </div>
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
    </div>
</ion-content>
