import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-login-success',
  templateUrl: './login-success.page.html',
  styleUrls: ['./login-success.page.scss'],
})
export class LoginSuccessPage implements OnInit {

  public customerId: string;

  constructor(
    private _activedRouter: ActivatedRoute,
    private _router: Router,
    private _firebase: FirebaseService,
  ) { }

  ngOnInit() {
    // initalize firebase
    this._firebase.init();

    // initalize theme
    this.customerId = this._activedRouter.snapshot.queryParams.id;
  }

  public continue() {
    this._router.navigate(["/select-service"]);
  }

}
