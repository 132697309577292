<div *ngIf="isLoading" class="loading-wrapper">
  <div class="text">
    Loading...
  </div>
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
</div>
<ion-content *ngIf="settings">
  <ion-list lines="none">
    <ion-item>
      <ion-list-header class="page-title">Notifications</ion-list-header>
    </ion-item>
    <ion-item>
      <ion-label>Bus start:</ion-label>
      <ion-toggle (ionChange) ="updateInput()" [(ngModel)]="settings.busAtStart" color="primary"></ion-toggle>
    </ion-item>
    <ion-item>
      <ion-label>Bus at stop:</ion-label>
      <ion-toggle (ionChange) ="updateInput()" [(ngModel)]="settings.busAtStop" color="primary"></ion-toggle>
    </ion-item>
    <ion-item>
      <ion-label>X number of stops before:</ion-label>
      <ion-toggle (ionChange) ="updateInput()" [(ngModel)]="settings.stopsBefore.enabled" color="primary"></ion-toggle>
    </ion-item>
    <ion-item lines="inset" [hidden]="!settings.stopsBefore.enabled">
      <ion-input (ionBlur)="updateInput()" type="number" [(ngModel)]="settings.stopsBefore.count" placeholder="number of stops"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>Traveller entering the bus:</ion-label>
      <ion-toggle (ionChange) ="updateInput()" [(ngModel)]="settings.entering" color="primary"></ion-toggle>
    </ion-item>
    <ion-item>
      <ion-label>Traveller going out of the bus:</ion-label>
      <ion-toggle (ionChange) ="updateInput()" [(ngModel)]="settings.goingOut" color="primary"></ion-toggle>
    </ion-item>
      <hr>
    <ion-item>
      <p class="notify">How would you like to receive notifications</p>
    </ion-item>
    <ion-item>
      <ion-label>Email:</ion-label>
      <ion-toggle (ionChange) ="updateInput()" [(ngModel)]="settings.notifyByEmail" color="primary"></ion-toggle>
    </ion-item>
    <ion-item>
      <ion-label>App Notification:</ion-label>
      <ion-toggle (ionChange) ="updateInput()" [(ngModel)]="settings.notifyPush" color="primary"></ion-toggle>
    </ion-item>
    <hr>
    <ion-item (click)="logOut()">
      <ion-label class="logout"><ion-icon size="large" name="log-out-outline"></ion-icon> LogOut</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
