import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'groupByDay',
})

export class GroupByDayPipe implements PipeTransform {

    transform(value: any, groupByKey: string) {

        // this gives an object with dates as keys
        const groups = value.reduce((groups, obj) => {

            let date;
            if(obj[groupByKey]) {
                date = obj[groupByKey].split('T')[0];
            } else {
                date = obj.date.split('T')[0];
            }

            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(obj);
            return groups;
        }, {});

        // Edit: to add it in the array format instead
        const groupArrays = Object.keys(groups).map((date) => {
            return {
                key: new Date(date),
                list: groups[date]
            };
        });

        return groupArrays.sort((n2,n1) => n1.key.getTime() - n2.key.getTime());

    }
}