import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BusEta } from 'src/app/app';
import { NotifierService } from 'src/app/services/notify.service';
import { PublicRoutesService } from 'src/app/services/public.routes.service';

@Component({
    selector: 'app-routes-list',
    templateUrl: './routes-list.component.html',
    styleUrls: ['./routes-list.component.scss'],
})
export class RoutesListComponent implements OnChanges {

    @Input() database = "";

    @Output() routeSelected = new EventEmitter<BusEta.Route>();

    public routes: BusEta.Route[] = [];

    public isLoading = false;

    private _loadedRoutes: BusEta.Route[] = [];

    constructor(
        private _notify: NotifierService,
        private _routesService: PublicRoutesService
    ) { }

    ngOnChanges() {
        if (this.database) {
            this.init();
        }
    }

    private async init() {
        try {
            this.isLoading = true;
            this._loadedRoutes = await this._routesService.find(this.database);
            this.routes = this._loadedRoutes.filter(r => r.name.trim() !== "").slice(0);
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }


    public search(event: Event) {
        const query = (event.target as HTMLInputElement).value.toLowerCase();

        if (!query || !query.trim()) {
            this.routes = this._loadedRoutes.filter(r => r.name.trim() !== "").slice(0);
            return;
        }

        this.routes = this._loadedRoutes.filter(r =>
            (r.name && r.name.toLowerCase().includes(query.toLowerCase()))
        );
    }

    public selectRoute(route: BusEta.Route) {
        this.routeSelected.emit(route);
    }
}
