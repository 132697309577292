import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private _http: HttpClient,
    private _auth: AuthService) { }

  public async post(url: string, data?: any) {

    const token = this._auth.token || "";

    const httpOptions = {
      headers: new HttpHeaders({
        "Authorization": token
      })
    };

    let res = await this._http.post(`${environment.service}${url}`,
      data ? data : {},
      httpOptions).toPromise()
    return res as any;

  }


}
