import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { MainPageRoutingModule } from './main-routing.module';

import { MainPage } from './main.page';
import { MapComponent } from './map/map.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SettingsComponent } from './settings/settings.component';
import { GroupByDayPipe } from "../../pipes/filter.pipe";
import { SearchRoutesComponent } from "./map/search-routes/search-routes.component";
import { RoutesService } from "../../services/routes.service";
import { NgBottomSheetModule } from "ng-bottom-sheet";
import { PublicRoutesService } from "../../services/public.routes.service";
import { PublicRoutesComponent } from "./public/map/search-routes/search-routes.component";
import { NotifyStopService } from "../../services/notify.stop.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BannerComponent } from "./banner/banner.component";
import { SharedPageComponent } from './public/shared-page/shared-page.component';
import { RoutesListComponent } from './public/routes-list/routes-list.component';
import { ActiveRoutePanelComponent } from './public/active-route-panel/active-route-panel.component';
import { PublicMapComponent } from './public/map/map/map.component';
import { MatMenuModule } from '@angular/material/menu';
import { RoutesListBottomSheetComponent } from './public/routes-list-bottom-sheet/routes-list-bottom-sheet.component';
import { StopsListComponent } from './public/stops-list/stops-list.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        MainPageRoutingModule,
        NgBottomSheetModule,
        MatProgressSpinnerModule,
        MatMenuModule
    ],
  declarations: [
    MapComponent,
    PublicMapComponent,
    PublicRoutesComponent,
    SearchRoutesComponent,
    NotificationsComponent,
    SettingsComponent,
    MainPage,
    GroupByDayPipe,
    BannerComponent,
    SharedPageComponent,
    RoutesListComponent,
    RoutesListBottomSheetComponent,
    ActiveRoutePanelComponent,
    StopsListComponent    
  ],
  providers: [
    RoutesService,
    PublicRoutesService,
    NotifyStopService
  ]
})
export class MainPageModule {}
